import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

import GustIcon from '../images/guest.svg';

type ContainerProps = { borderWidth: number; size: number; borderColor?: string };

const Container = styled.span<ContainerProps>`
  min-width: ${({ size }) => `${size}px`};
  max-width: ${({ size }) => `${size}px`};
  min-height: ${({ size }) => `${size}px`};
  max-height: ${({ size }) => `${size}px`};
  display: block;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  padding: ${({ borderWidth }) => `${borderWidth}px`};
  padding-top: 2%;
  background-color: ${({ borderColor, theme }) => borderColor || theme.colors.D70};
`;

const Img = styled(GustIcon)<{ size: number; borderwidth: number }>`
  width: ${({ size, borderwidth }) => `${size - borderwidth * 2}px`};
  height: ${({ size, borderwidth }) => `${size - borderwidth * 2}px`};
  border-radius: 50%;
  display: block;
  margin: auto auto;
`;

const GuestAvatar = ({ ...rest }: ContainerProps & ComponentPropsWithoutRef<'span'>) => (
  <Container {...rest}>
    <Img size={20} borderwidth={rest.borderWidth} />
  </Container>
);

export default GuestAvatar;
