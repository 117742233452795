import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SetterOrUpdater } from 'recoil';
import { Text, DropdownSelect } from '@powtoon/design-system-components';

import { useBITracking, BILabel } from '~/shared/hooks/useTracking';
import CommentsFilterType from '~/shared/types/CommentsFilterType';

const IconWithTextWrapper = styled.div`
  align-content: right;
  height: 70px;
  padding: 25px 10px 25px 25px;
  //width: 100%;
  justify-content: space-between;
  display: flex;
  gap: 10px;
  width: 100%;
  .vcp-dropdown-select__selected-value {
    overflow: visible !important;
    width: 100%;
    text-align: right;
  }

  .vcp-dropdown-select__selected-option {
    padding-top: 0px;
  }
`;
const CommentsWrappers = styled.div`
  width: 105px;

  .no-margin {
    margin-block-start: 0;
  }
`;

const FilterDropDownWrapper = styled.div`
  width: 100%;
  > svg {
    pointer-events: none;
  }
`;
const FilterDropDown = styled(DropdownSelect)`
  width: 200%;
`;

interface Props {
  setCurrentFilter: SetterOrUpdater<CommentsFilterType>;
  statusFiltersEnabled: boolean;
  focusMode: boolean;
}

const CommentsListHeader = ({ setCurrentFilter, statusFiltersEnabled, focusMode }: Props): JSX.Element => {
  const { track } = useBITracking({});
  const menuRef = useRef<HTMLDivElement>(null);
  const [openFiltersMenu, setOpenFiltersMenu] = useState(false);

  useEffect(() => {
    if (!focusMode && openFiltersMenu == true && menuRef.current) {
      const menuButtonElem = menuRef.current?.children[0].children[0] as HTMLElement;
      menuButtonElem.click();
      const timer = setTimeout(() => setOpenFiltersMenu(false), 200);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusMode]);

  const handleClickOutside = React.useCallback((event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpenFiltersMenu(false);
    }
  }, []);

  useEffect(() => {
    if (openFiltersMenu) {
      window.addEventListener('click', handleClickOutside);
    }
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside, openFiltersMenu]);

  const handleOnClick = React.useCallback(() => {
    if (focusMode) {
      setOpenFiltersMenu(prevValue => !prevValue);
    }
  }, [focusMode]);

  return (
    <IconWithTextWrapper>
      <CommentsWrappers>
        <Text fontStyle="h5" color="success" className="no-margin">
          Comments
        </Text>
      </CommentsWrappers>
      {statusFiltersEnabled && (
        <FilterDropDownWrapper onClick={handleOnClick} className="Filters" ref={menuRef}>
          <FilterDropDown
            data-cy="filter-drop-down"
            mode="text"
            placeholder="Open Comments"
            panelTitle="Filter status by"
            align={{
              offset: [-20, 5],
            }}
            options={[
              {
                key: 'All',
                value: 'Open Comments',
              },
              {
                key: 'Resolved',
                value: 'Resolved Comments',
              },
              // {
              //   key: 'Unread',
              //   value: 'Unread Comments',
              // },
              {
                key: 'Mentions',
                value: 'Mentions',
              },
            ]}
            onChange={option => {
              setOpenFiltersMenu(false);
              let trackingLabel: BILabel = 'open';
              switch (option.key) {
                case 'All': {
                  trackingLabel = 'open';
                  setCurrentFilter(CommentsFilterType.ALL);
                  break;
                }
                case 'Resolved': {
                  trackingLabel = 'resolved';
                  setCurrentFilter(CommentsFilterType.RESOLVED);
                  break;
                }
                case 'Unread': {
                  trackingLabel = 'unread';
                  setCurrentFilter(CommentsFilterType.UNREAD);
                  break;
                }
                case 'Mentions': {
                  trackingLabel = 'mentions';
                  setCurrentFilter(CommentsFilterType.MENTIONED);
                  break;
                }
              }
              track({
                action: 'filter',
                label: trackingLabel,
                value: '10340',
              });
            }}
          ></FilterDropDown>
        </FilterDropDownWrapper>
      )}
    </IconWithTextWrapper>
  );
};

export default CommentsListHeader;
