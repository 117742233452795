/**
 * Comment Ability Lookup
 * 
 *  "can_add_comments",
    "can_duplicate",
    "can_edit",
    "can_export",
    "can_rename",
    "can_resolve_comments",
    "can_send_a_copy",
    "can_share",
    "can_view",
    "can_view_comments",
    "edit",
    "share",
    "view" -- legacy (don't support it)
 */
export type COMMENT_ABILITY = 'can_add_comments' | 'can_resolve_comments' | 'can_view_comments' | 'can_share';

export const Abilities = {
  ADD_COMMENT: 'can_add_comments' as COMMENT_ABILITY,
  RESOLVE_COMMENT: 'can_resolve_comments' as COMMENT_ABILITY,
  VIEW_COMMENTS: 'can_view_comments' as COMMENT_ABILITY,
  SHARE_POWTOON: 'can_share' as COMMENT_ABILITY,
};
