import { PowtoonStudio } from '@powtoon/plugin-lib';
import { useCallback } from 'react';

import { fetchPowtoonAccessToken } from '~/shared/helpers/AuthHelper';

import useHostStateData from './useHostStateData';

export const useStudio = ({ studio }: { studio: PowtoonStudio }) => {
  const [hostDataState, hostDataActions] = useHostStateData();

  const refreshPowtoonAccessToken = useCallback(async () => {
    //console.info('refreshPowtoonAccessToken() invoked...');
    const powtoonAccessToken = await fetchPowtoonAccessToken(studio);
    hostDataActions.setCurrentUser({
      userId: powtoonAccessToken.userId,
      permissions: powtoonAccessToken.permissions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studio]);

  const refetchPowtoonUsers = async (userIds: string[]) => {
    //console.info('refetchPowtoonUsers() invoked...');
    const users = await studio.getDataHandler('getUsersById', userIds);
    hostDataActions.setPowtoonUsers(users);
    return users;
  };

  return {
    refreshPowtoonAccessToken,
    refetchPowtoonUsers,
  };
};
