import styled, { css } from 'styled-components';

export const flex = css`
  display: flex;
`;

export const Flex = styled.div`
  ${flex};
`;

export const flexCenter = css`
  ${flex};
  justify-content: center;
  align-items: center;
`;

export const FlexCenter = styled.div`
  ${flexCenter};
`;

export const flexCenterTop = css`
  ${flex};
  justify-content: center;
`;

export const FlexCenterTop = styled.div`
  ${flexCenterTop}
`;

export const flexColumn = css`
  ${flex};
  flex-direction: column;
`;

export const FlexColumn = styled.div`
  ${flexColumn};
`;

export const flexColumnCenter = css`
  ${flex};
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FlexColumnCenter = styled.div`
  ${flexColumnCenter};
`;

export const flexMiddle = css`
  ${flex};
  align-items: center;
`;

export const FlexMiddle = styled.div`
  ${flexMiddle};
`;

export const flexSpace = css`
  ${flex};
  justify-content: space-between;
  align-items: center;
`;

export const FlexSpace = styled.div`
  ${flexSpace};
`;

export const flexSpaceAround = css`
  ${flex};
  justify-content: space-around;
  align-items: center;
`;

export const FlexSpaceAround = styled.div`
  ${flexSpaceAround};
`;
