const colors = {
  primary: '#3a57fa',
  secondary: '#0d3750',

  white: '#ffffff',

  borderLight: '#e7eaf0',

  textLight: '#a1a1a1',
  textLight2: '#bfc4cf',

  textMedium: '#8fa1bc',
  textDark: '#0a2138',

  textLink: '#0a7fff',

  backgroundLight: '#f6f8fc',
  backgroundLight2: '#f3f5ff',
  backgroundMedium: '#c6cbce',
  backgroundHover: '#F8F8F9',
  backgroundHoverDarkness: '#cdd5fe',
  error: '#F14343',

  backgroundDark: '#082d43',

  divider: 'rgba(67, 85, 103, 0.15)',

  hoverLight: '#eee',

  D20: '#696F89',
  D30: '#8D92A5',
  D60: '#D3D5DD',
  D70: '#E4E5EA',
  D90: '#F8F8F9',
  C20: '#1E3B68',
  B80: '#F3F5FF',
};

export default colors;
