import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Text } from '@powtoon/design-system-components';

import { getProperCommentCreationText } from '~/shared/utils';

const CommentCreatedTime = styled(Text)`
  align-self: center;
  font-size: 12.5px;
  margin: 0px;
`;

export const TickerContext = React.createContext(new Date());

export class BaseCommentTimeTicker extends React.Component<{ children?: ReactNode; createdAt: string }> {
  interval: any;
  state = {
    now: new Date(),
    createdAt: '',
  };

  constructor(props: any) {
    super(props);
    this.state = {
      now: new Date(),
      createdAt: this.props.createdAt,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ now: new Date() });
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <CommentCreatedTime fontStyle="small" color="secondary2" data-cy="comment-time-from-creation">
        <TickerContext.Provider value={this.state.now}>
          {getProperCommentCreationText(this.state.now.getTime(), Date.parse(this.props.createdAt))}
        </TickerContext.Provider>
      </CommentCreatedTime>
    );
  }
}
