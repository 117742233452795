import moment from 'moment';
interface TemplateProps {
  user: { name: string; image: string };
  comment: { createdAt: string; text: string };
}

export default ({ user, comment }: TemplateProps): string => {
  return ` 
<div>
    <div>
        <img width="45px" height="45px" style="float:left;" src="${user.image}"> 
        <span style="margin-left:20px;color:rgb(0,0,0)">${user.name}
          <br/>
          <span style="margin-left:20px;color:rgba(0,0,0,.3)">${comment.createdAt}</span>
        </span>
    </div>                                            
</div>
<div style="margin-top:30px;">
  <div style="text-align:left;color:#000;word-break:break-word">${comment.text}</div>
                                   
  <div align="center" bgcolor="#3A57FA" role="presentation" style="margin-top:30px;width:194px;border:none;border-radius:25px; background:#3a57fa" >
    <a href="{{powtoon.url}}"
      style="display:inline-block;background:#3a57fa;color:#fff;font-size:18px;line-height:15px;width:185px;margin:0;text-decoration:none;text-transform:none;padding:18px 24px 17px 24px;mso-padding-alt:0;border-radius:25px"
      target="_blank">Reply on Powtoon</a>
  </div>                                       
</div>`;
};
