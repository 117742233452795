// import { PowtoonStudio, StudioModelEvent, StudioTarget, StudioUiEvent } from '../apis';
// // import { BadgeSpec, MenuSpec } from '../apis/common';

import {
  PowtoonStudio,
  CoreMessagingProvider,
  StudioModelEvent,
  StudioTarget,
  StudioUiEvent,
  BadgeSpec,
  MenuSpec,
  NotificationDescriptor,
  PowtoonUser,
  DialogEvents,
} from '../../apis';

export class PowtoonStudioProvider implements PowtoonStudio {
  private uiEventHandlers: Parameters<PowtoonStudio['registerUiEventHandler']>[0][] = [];
  private modelEventHandlers: Parameters<PowtoonStudio['registerModelEventHandler']>[0][] = [];

  apiName: 'PowtoonStudio' = 'PowtoonStudio';

  constructor(private readonly messaging: CoreMessagingProvider) {
    messaging.setReceiver(msg => {
      if (msg.apiName === this.apiName) {
        if (msg.methodName === 'StudioModelEvent') {
          this.modelEventHandlers.forEach(h => h(msg.arg));
        }
        if (msg.methodName === 'StudioUiEvent') {
          this.uiEventHandlers.forEach(h => h(msg.arg));
        }
      }
    });
  }

  private send(methodName: string, arg: any) {
    return this.messaging.send({ apiName: this.apiName, methodName, arg });
  }

  private sendReceive(methodName: string, arg: any): Promise<any> {
    return this.messaging.sendReceive({ apiName: this.apiName, methodName, arg });
  }

  getPowtoonAccessToken(): Promise<{ id?: number }> {
    return this.sendReceive('getPowtoonAccessToken', {});
  }

  getPowtoonUsers(): Promise<{ [key: string]: PowtoonUser }> {
    return this.sendReceive('getPowtoonUsers', {});
  }

  getDataHandler(methodName: string, arg: any): Promise<any> {
    return this.sendReceive(methodName, arg);
  }

  notifyUsers(arg: NotificationDescriptor): void {
    this.send('notifyUsers', arg);
  }

  openShareDialog(arg: { event: DialogEvents }): void {
    this.send('openShareDialog', arg);
  }

  navigateToTarget(arg: { target: StudioTarget }): void {
    this.send('navigateToTarget', arg);
  }

  setBadge(arg: { target: StudioTarget; badge: BadgeSpec }): void {
    this.send('setBadge', arg);
  }

  setMenu(arg: { target: StudioTarget; menu: MenuSpec }): void {
    this.send('setMenu', arg);
  }

  pause(): void {
    this.send('pause', {});
  }

  ready(): void {
    this.send('ready', {});
  }

  registerModelEventHandler(handler: (event: StudioModelEvent) => void): void {
    this.modelEventHandlers.push(handler);
  }
  registerUiEventHandler(handler: (event: StudioUiEvent) => void): void {
    this.uiEventHandlers.push(handler);
  }
}
