import React from 'react';

import { MentionData } from '~/shared/recoil';

import RichTextForm from './RichTextForm';

const ReplyForm = ({
  onReplyInputChange,
  //replyText,
  onCancelButtonClick,
  onSubmitButtonClick,
  mentionSuggestion,
}: {
  replyText: string;
  onReplyInputChange: (value: string, n: MentionData[]) => void;
  onCancelButtonClick: () => void;
  onSubmitButtonClick: () => void;
  mentionSuggestion: MentionData[];
}) => {
  return (
    <>
      <RichTextForm
        onCancelButtonClick={onCancelButtonClick}
        onOkButtonClick={onSubmitButtonClick}
        okButtonText="Reply"
        onSubmit={(evt: string, n: MentionData[]) => onReplyInputChange(evt, n)}
        mentionSuggestion={mentionSuggestion}
      />
    </>
  );
};

export default ReplyForm;
