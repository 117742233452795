import React, { forwardRef, Ref, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import { Text, Icon } from '@powtoon/design-system-components';

import BaseComment, { BaseCommentProps } from '~/shared/components/BaseComment';
import { Comment as CommentType, Reply as ReplyType, selectedCommentIdState } from '~/shared/recoil';
import BaseCommentReplies from '~/shared/components/BaseComment/BaseCommentReplies';

import RepliesList from './RepliesList';

const HideRepliesButton = styled.div`
  margin-left: 10px;
  cursor: pointer;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ArrowUpIcon = styled(Icon)`
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.D30};
`;

const InlineText = styled(Text)`
  display: inline-block;
  margin: 0px;
  margin-left: 5px;
`;

const Root = styled.div`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  .unread-comment-highlight {
    position: relative;
    margin: 10px;
  }
  .unread-comment-highlight > div:first-child {
    cursor: pointer;
    /* background: ${({ theme }) => theme.colors.backgroundHover}; */
  }
`;

const Comment = (
  {
    comment,
    ...rest
  }: Omit<BaseCommentProps, 'item'> & {
    comment: CommentType;
    onCommentClick: (data: CommentType) => void;
  },
  ref: Ref<HTMLDivElement>
) => {
  const [isRepliesShown, setRepliesShown] = useState(false);
  const [selectedCommentId] = useRecoilState(selectedCommentIdState);
  const currentComment = React.useRef<HTMLDivElement>(null);

  const removeReply = (prevComments: CommentType[] | ReplyType[], replyId: string): CommentType[] | ReplyType[] => {
    return prevComments.map((c: CommentType | ReplyType) => {
      return { ...c, replies: c.replies.filter(r => r.id !== replyId) };
    });
  };

  useEffect(() => {
    if (selectedCommentId == comment.id) {
      const { current } = currentComment;
      if (current) {
        const timer = setTimeout(() => {
          current?.parentElement?.parentElement?.scrollTo({
            behavior: 'smooth',
            top: current?.parentElement?.offsetTop,
            left: 0,
          });
        }, 200);
        return () => clearTimeout(timer);
      }
    }
  }, [selectedCommentId, comment]);

  return (
    <Root
      ref={ref}
      onClick={() => {
        rest?.onCommentClick(comment);
      }}
    >
      <BaseComment
        item={{
          text: comment.richText,
          slideId: comment.slideId,
          id: comment.id,
          isRead: comment.isRead,
          createdAt: comment.createdAt,
          isResolved: !!comment.resolvedAt,
          authorId: comment.userId,
          isComment: true,
        }}
        ref={currentComment}
        setRepliesShown={setRepliesShown}
        className={comment.isRead ? '' : 'unread-comment-highlight'}
        {...rest}
      >
        {!!comment.replies.length && !isRepliesShown && (
          <BaseCommentReplies comment={comment} setRepliesShown={setRepliesShown}></BaseCommentReplies>
        )}
        {isRepliesShown && (
          <RepliesList
            replies={comment.replies}
            removeReply={(reply: ReplyType) => {
              if (rest.setComments) {
                (rest.setComments as SetterOrUpdater<CommentType[]>)(
                  prevComments => removeReply(prevComments, reply.id) as CommentType[]
                );
              }
            }}
            setComments={rest.setComments}
            refreshPowtoonAccessToken={rest.refreshPowtoonAccessToken}
            currentFilter={rest.currentFilter}
            slideId={comment.slideId}
          />
        )}
        {!!comment.replies.length && isRepliesShown && (
          <HideRepliesButton onClick={() => setRepliesShown(false)}>
            <ArrowUpIcon name="arrow_up" color="secondary2" size="medium" />
            <InlineText color="secondary2" fontStyle="medium">
              Hide replies
            </InlineText>
          </HideRepliesButton>
        )}
      </BaseComment>
    </Root>
  );
};

export default forwardRef(Comment);
