import React, { forwardRef, Ref, useState, useEffect, ComponentPropsWithRef } from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import {
  Button,
  ContextMenu as _ContextMenu,
  Text,
  ContextMenuListItem,
  UserAvatars as _UserAvatars,
} from '@powtoon/design-system-components';

import { useBITracking } from '~/shared/hooks/useTracking';
import useReplyActions from '~/shared/hooks/useReplyActions';
import {
  Comment as CommentType,
  commentEditorState,
  Reply as ReplyType,
  activeReplyEditorState,
  selectedCommentIdState,
} from '~/shared/recoil';
import { insertComments } from '~/shared/utils';
import useHostStateData from '~/shared/hooks/useHostStateData';
import { IsAllowed } from '~/shared/components/Acl/Abilities';
import { Abilities } from '~/shared/helpers/PermissionHelper';
import CommentsFilterType from '~/shared/types/CommentsFilterType';
import theme from '~/shared/theme';

import ReplyForm from './ReplyForm';
import CommentTextView from './CommentTextView';
import { BaseCommentTimeTicker } from './BaseCommentTime';
import GuestAvatar from '../GuestAvatar';

const Root = styled.div`
  position: relative;
  margin: 5px;
  word-break: break-word;
  &.comment-reply {
    margin: 5px 0px 10px 10px;
  }
  &.comment-reply > div {
    border-left: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 0px;
    margin: 10px 0px 15px 5px;
    padding: 0px;
    padding-left: 10px;
  }
`;

// margin: 10px 0px 15px 5px;
// padding-left: 10px;
const ContextMenu = styled(_ContextMenu)`
  color: ${theme.colors.D30};
  :hover {
    background-color: rgb(58 87 250 / 10%) !important;
  }
`;

const BaseCommentWrapper = styled.div`
  padding: 10px 0px 15px 15px;
  border-radius: 8px;
  :hover {
    cursor: pointer;
  }
`;

const FormWrapper = styled.div`
  padding: 10px;
`;

const InlineText = styled(Text)`
  display: inline;
  margin: 0px;
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 17px;
`;

const UserName = styled(Text)`
  align-self: center;
  margin: 0;
`;

const UserAvatars = styled(_UserAvatars)`
  margin-right: 10px;
  img {
    border-radius: 25px;
  }
`;

const TextsPart = styled.span`
  align-items: center;
`;

const CommentTextWrapper = styled.div`
  padding-right: 20px;
`;

const TextsAndMoreButtonLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const MoreButtonPart = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 11px;
  .pointer {
    cursor: pointer;
  }
`;

const InfoLabelSeparator = styled.span<{ $isError?: boolean }>`
  color: ${({ theme, $isError }) => ($isError ? theme.colors.error : theme.colors.D60)};
`;

const SlideInfoLabel = styled.span<{ $isError?: boolean }>`
  color: ${({ theme, $isError }) => ($isError ? theme.colors.error : theme.colors.primary)};
`;

const CommentFooter = styled.footer`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-top: 5px;
`;

const ContainerFormWrapper = styled.div``;

export type BaseCommentProps = ComponentPropsWithRef<'div'> & {
  isMoreButton?: boolean;
  onDeleteButtonClick?: () => void;
  onUpdateButtonClick?: (richText: string, slideId: string, mentions?: any[]) => void;
  onResolveButtonClick?: (id: string, status: boolean) => void;
  onCreateReplyClick?: (data: ReplyType, commentId: string) => void;
  onMarkReadStatusClick?: (id: string, status: boolean) => void;
  item: {
    id: string;
    text: string;
    slideId: string;
    isResolved?: boolean;
    isRead?: boolean;
    createdAt: string;
    authorId?: string;
    isComment?: boolean;
  };
  user: { id: string; name: string; image: string };
  currentFilter: CommentsFilterType;
  refreshPowtoonAccessToken: () => void;
  timeInfo?: { text: string; isError?: boolean };
  positionInfo?: { text: string; isError?: boolean };
  isRepliesEnabled?: boolean;
  setComments?: SetterOrUpdater<CommentType[]>;
  setRepliesShown?: SetterOrUpdater<boolean>;
  setReplyBackground?: SetterOrUpdater<string>;
};

const Comment = (
  {
    isMoreButton,
    onDeleteButtonClick,
    onUpdateButtonClick,
    onResolveButtonClick,
    // onMarkReadStatusClick,
    item,
    user,
    timeInfo,
    positionInfo,
    className,
    children,
    setComments,
    setRepliesShown,
    setReplyBackground,
    isRepliesEnabled,
    currentFilter,
    onCreateReplyClick,
    refreshPowtoonAccessToken,
  }: BaseCommentProps,
  ref: Ref<HTMLDivElement>
) => {
  const [isReplyInputOpen, setReplyInputOpen] = useState(false);
  const [replyText, setReplyText] = useState('');
  const { createReplyOnServer } = useReplyActions();
  const [editorBox, setMainCommentEditorBox] = useRecoilState(commentEditorState);
  const [activeReplyState, setActiveReplyState] = useRecoilState(activeReplyEditorState);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCommentId, setSelectedCommentId] = useRecoilState(selectedCommentIdState);
  const [editMode, setEditMode] = useState(false);
  const [commentBackground, setCommentBackground] = useState(theme.colors.white);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRead, setCommentRead] = useState(item.isRead || false);
  const { track } = useBITracking({});

  const replyRef = React.useRef<HTMLDivElement>(null);
  const [hostDataState] = useHostStateData();

  useEffect(() => {
    if (hostDataState.selectedCommentId === item.id) {
      setCommentBackground(theme.colors.B80);
      if (setReplyBackground) {
        setReplyBackground(theme.colors.B80);
      }
    } else {
      setCommentBackground(theme.colors.white);
      if (setReplyBackground) {
        setReplyBackground(theme.colors.white);
      }
    }
  }, [hostDataState.selectedCommentId]);

  const resetReplyInput = () => {
    setReplyInputOpen(false);
    setReplyText('');
  };

  const saveWithTokenRefresher = async (next: any, args: any, tries = 1) => {
    for (let i = tries; i >= 0; i--) {
      try {
        const data = await next(args);
        return data;
      } catch (error) {
        await refreshPowtoonAccessToken();
      }
    }
  };

  const isWaitingForServerAnswer = (): boolean => !item.id.includes('-');

  const optimisticUpdateInsertReply = (reply: ReplyType, replyToId: string, rollback?: boolean): void => {
    if (setComments) {
      setComments(
        (prevComments: CommentType[]) => insertComments(prevComments, reply, replyToId, rollback) as CommentType[]
      );
    }
  };

  const createReply = async (): Promise<void> => {
    const randomId = `${Math.floor(Math.random() * 23454666) + 1}`;
    const newReply: ReplyType = {
      id: randomId,
      userId: hostDataState.currentUser?.userId,
      richText: replyText,
      createdAt: '',
      replies: [],
    };

    onCreateReplyClick?.(newReply, item.id);

    try {
      optimisticUpdateInsertReply(newReply, item.id);
      setReplyInputOpen(false);
      if (setRepliesShown) {
        setRepliesShown(true);
      }

      const resp = await saveWithTokenRefresher(
        createReplyOnServer,
        {
          variables: {
            replyToId: item.id,
            richText: replyText,
          },
        },
        1
      );

      if (setComments) {
        if (!resp.data.addReply) {
          optimisticUpdateInsertReply(newReply, item.id, true);
        } else {
          const newId = resp.data.addReply;
          track({
            action: 'post',
            label: 'reply',
            value: '10332',
            extra: {
              replyToId: item.id,
              richText: replyText,
              id: newId,
              slideId: item.slideId,
            },
          });
          setComments(
            (prevComments: CommentType[]) =>
              insertComments(prevComments, { ...newReply, id: newId }, item.id, false, randomId) as CommentType[]
          );
        }
      }

      resetReplyInput();
    } catch (err) {
      if (setComments) {
        optimisticUpdateInsertReply(newReply, item.id, true);
      }
    }
  };

  const scrollToComment = (): void => {
    setSelectedCommentId(item.id);
  };

  const closeOpenEditors = (): void => {
    setActiveReplyState('');
    setReplyInputOpen(false);
    setMainCommentEditorBox(!editorBox && false);
  };

  const onEditButtonClick = () => {
    track({
      action: 'edit',
      label: 'context_menu',
      value: '10338',
      extra: { id: item.id },
    });
    scrollToComment();
    closeOpenEditors();
    setEditMode(true);
  };

  const clearHover = () => {
    if (item.id === hostDataState.selectedCommentId) return;
    setCommentBackground(theme.colors.white);
    if (setReplyBackground) {
      setReplyBackground(theme.colors.white);
    }
  };
  const applyHover = () => {
    if (item.id === hostDataState.selectedCommentId) return;
    setCommentBackground(theme.colors.D90);
    if (setReplyBackground) {
      setReplyBackground(theme.colors.D90);
    }
  };

  const onContextClick = () => {
    track({
      action: 'click',
      label: 'comment_context_menu',
      value: '10335',
      extra: { id: item.id },
    });
  };

  const renderUserInfo = (): JSX.Element => {
    const isOwner = hostDataState.currentUser.userId == hostDataState.powtoonOwner?.id;
    const isAuthor = item.authorId == hostDataState.currentUser.userId;
    const isResolvedPanelActive = currentFilter == CommentsFilterType.RESOLVED;
    return (
      <UserInfo>
        {user.image.length ? (
          <UserAvatars avatarsSize="large" maxVisibleItem={1} users={[user]} />
        ) : (
          <GuestAvatar borderWidth={0} size={35} borderColor="#E4E5EA" style={{ marginRight: 10 }} />
        )}
        <TextsAndMoreButtonLayout data-cy="comment-header">
          <TextsPart>
            <UserName fontStyle="medium">{user.name}</UserName>
            <BaseCommentTimeTicker createdAt={item.createdAt}></BaseCommentTimeTicker>
          </TextsPart>
          {isMoreButton && (
            <MoreButtonPart onClick={onContextClick} data-cy="comment-more-button">
              {isWaitingForServerAnswer() && <Loader type="TailSpin" color="#556DFB" height={20} width={20} />}
              <ContextMenu
                placement="bottom"
                data-cy="modal-buttons"
                onMouseEnter={clearHover}
                onMouseLeave={applyHover}
              >
                {!isResolvedPanelActive && item.isComment && (isOwner || isAuthor) && (
                  <IsAllowed to={Abilities.RESOLVE_COMMENT} user={hostDataState.currentUser}>
                    <ContextMenuListItem
                      data-cy="comment-resolve"
                      iconName="checkbox"
                      onClick={() => {
                        track({
                          action: 'resolve',
                          label: 'context_menu',
                          value: '10336',
                          extra: { id: item.id },
                        });
                        if (onResolveButtonClick) onResolveButtonClick(item.id, true);
                      }}
                      title="Resolve"
                    />
                  </IsAllowed>
                )}
                {isResolvedPanelActive && item.isComment && (isOwner || isAuthor) && (
                  <IsAllowed to={Abilities.RESOLVE_COMMENT} user={hostDataState.currentUser}>
                    <ContextMenuListItem
                      data-cy="comment-open-resolved"
                      iconName="checkbox"
                      onClick={() => {
                        track({
                          action: 'reopen',
                          label: 'context_menu',
                          value: '10336',
                          extra: { id: item.id },
                        });
                        if (onResolveButtonClick) onResolveButtonClick(item.id, false);
                      }}
                      title="Reopen"
                    />
                  </IsAllowed>
                )}
                {isAuthor && (
                  <IsAllowed to={Abilities.ADD_COMMENT} user={hostDataState.currentUser}>
                    <ContextMenuListItem
                      data-cy="comment-edit"
                      iconName="edit"
                      onClick={onEditButtonClick}
                      title="Edit"
                    />
                  </IsAllowed>
                )}

                {/* {currentFilter !== CommentsFilterType.RESOLVED && (
                  <ContextMenuListItem
                    iconName={item.isRead ? 'eyeclosed' : 'eyeopened'}
                    data-cy={item.isRead ? 'comment-mark-unread' : 'comment-mark-read'}
                    onClick={() => {
                      setCommentRead(!item.isRead);
                      onMarkReadStatusClick && onMarkReadStatusClick(item.id, !item.isRead);
                    }}
                    title={item.isRead ? 'Mark as unread' : 'Mark as read'}
                  />
                )} */}
                {isAuthor && (
                  <IsAllowed to={Abilities.ADD_COMMENT} user={hostDataState.currentUser}>
                    <ContextMenuListItem
                      data-cy="comment-delete"
                      iconName="trash"
                      isDangerColor
                      onClick={() => {
                        track({
                          action: 'delete',
                          label: 'context_menu',
                          value: '10339',
                          extra: { id: item.id },
                        });
                        if (onDeleteButtonClick) onDeleteButtonClick();
                      }}
                      title="Delete"
                    />
                  </IsAllowed>
                )}
              </ContextMenu>
            </MoreButtonPart>
          )}
        </TextsAndMoreButtonLayout>
      </UserInfo>
    );
  };

  const onReplyButtonClick = (): void => {
    track({
      action: 'click',
      label: 'reply',
      value: '10334',
      extra: { id: item.id },
    });
    setActiveReplyState(item.id);
    setReplyInputOpen(true);
    setMainCommentEditorBox(!editorBox && false);
    setEditMode(false);
    replyRef.current!.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  const onUpdate = (richText: string, slideId: string, mentions?: any[]): void => {
    setEditMode(false);
    item.text = richText;
    onUpdateButtonClick && onUpdateButtonClick(richText, slideId, mentions);
  };

  return (
    <Root ref={ref} className={className}>
      <BaseCommentWrapper
        style={{ backgroundColor: commentBackground }}
        onMouseEnter={applyHover}
        onMouseLeave={clearHover}
      >
        {renderUserInfo()}
        <CommentTextWrapper>
          {timeInfo && positionInfo && (
            <InlineText fontStyle="medium">
              <SlideInfoLabel $isError={positionInfo.isError} data-cy="comment-slide-nr">
                {positionInfo.text}
              </SlideInfoLabel>
              <InfoLabelSeparator>{' | '}</InfoLabelSeparator>
            </InlineText>
          )}
          <CommentTextView
            readOnly={!editMode}
            markdownText={item.text}
            onUpdateButtonClick={onUpdate}
            slideId={item.slideId}
            onCancelButtonClick={() => {
              setEditMode(false);
            }}
            mentionSuggestions={hostDataState.mentionSuggestions}
          />
        </CommentTextWrapper>
      </BaseCommentWrapper>
      {children}
      {isRepliesEnabled &&
        (!isReplyInputOpen || item.id != activeReplyState) &&
        !isWaitingForServerAnswer() &&
        !editMode && (
          <CommentFooter data-cy="comment-footer">
            <Button buttonType="secondary" mode="text" onClick={onReplyButtonClick} size="small" data-cy="reply-button">
              Reply
            </Button>
          </CommentFooter>
        )}
      <ContainerFormWrapper ref={replyRef}>
        {isReplyInputOpen && item.id == activeReplyState && (
          <FormWrapper>
            <ReplyForm
              mentionSuggestion={hostDataState.mentionSuggestions}
              replyText={replyText}
              onCancelButtonClick={resetReplyInput}
              onReplyInputChange={setReplyText}
              onSubmitButtonClick={createReply}
            />
          </FormWrapper>
        )}
      </ContainerFormWrapper>
    </Root>
  );
};

export default forwardRef(Comment);
