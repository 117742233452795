import { LogType } from '../contexts/LoggerContext';

interface LogzioLoggerConfig {
  defaultData: LogType;
  token: string;
  url?: string;
}

const DEFAULT_LOGZIO_URL = 'https://listener.logz.io:8091';

export default class LogzioLogger {
  #token: string;
  #url: string;
  #defaultData: { [key: string]: any };
  constructor({ token, url, defaultData }: LogzioLoggerConfig) {
    this.#token = token;
    this.#url = url || DEFAULT_LOGZIO_URL;
    this.#defaultData = defaultData;
  }
  log(data: string | { [key: string]: any }) {
    try {
      const logObj = typeof data === 'object' ? data : { message: data };
      const urlToReport = new URL(this.#url);
      const reportingParams = urlToReport.searchParams;
      reportingParams.append('token', this.#token);
      Object.keys(this.#defaultData).forEach(key => {
        reportingParams.append(`${key}`, this.#defaultData[key]);
      });
      Object.keys(logObj).forEach((key: keyof typeof logObj) => {
        reportingParams.append(`${key}`, logObj[key]);
      });
      const img = new Image();
      img.src = urlToReport.toString();
      // tslint:disable-next-line:no-console
      window.console.debug(`Logzio sent via ${img.src}`);
    } catch (e) {
      // tslint:disable-next-line:no-console
      window.console.error(`Failed to send log because of exception:\n${e}`);
    }
  }
}
