import React from 'react';
import styled from 'styled-components';
import { Button } from '@powtoon/design-system-components';

const Root = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
`;

const SmallButton = styled(Button)`
  padding: 6px 12px;
  margin: 10px 0 0 10px;
`;

const OkButton = styled(SmallButton)``;

const CancelButton = styled(SmallButton)``;

const ButtonsPanel = ({
  onCancelButtonClick,
  onOkButtonClick,
  cancelButtonText = 'Cancel',
  okButtonText = 'Post',
  disabled = true,
}: {
  okButtonText?: string;
  cancelButtonText?: string;
  onCancelButtonClick: () => void;
  onOkButtonClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <Root>
      {!disabled && (
        <CancelButton size="small" buttonType="secondary" mode="text" onClick={onCancelButtonClick}>
          {cancelButtonText}
        </CancelButton>
      )}
      <OkButton onClick={onOkButtonClick} size="small" buttonType="primary" data-cy="postButton" {...{ disabled }}>
        {okButtonText}
      </OkButton>
    </Root>
  );
};

export default ButtonsPanel;
