import moment from 'moment';

import { Comment as CommentType, Reply as ReplyType } from '~/shared/recoil';

const formatNum = (num: number) => (num >= 10 ? `${num}` : `0${num}`);

export const formatTime = (millisec?: number) => {
  if (!millisec) return '00:00';

  const seconds = Math.floor(millisec / 1000);

  if (seconds < 10) return `00:0${seconds}`;

  if (seconds < 60) return `00:${seconds}`;

  // if (seconds < 3600) {
  //   const minutes = Math.floor(seconds / 60);
  //   return `${formatNum(minutes)}:${formatNum(seconds - minutes * 60)}`;
  // }
  const minutes = Math.floor(seconds / 60);
  return `${formatNum(minutes)}:${formatNum(seconds - minutes * 60)}`;
  // const hours = Math.floor(seconds / 3600);

  // const minutes = Math.floor((seconds - hours * 3600) / 60);

  // return `${formatNum(hours)}:${formatNum(minutes)}:${formatNum(seconds - (hours * 3600 + minutes * 60))}`;
};

export const removeReply = (
  prevComments: CommentType[] | ReplyType[],
  reply: ReplyType
): CommentType[] | ReplyType[] => {
  return prevComments
    .filter(c => c.id !== reply.id)
    .map((c: CommentType | ReplyType) => ({ ...c, replies: removeReply(c.replies, reply) }));
};

export const insertComments = (
  prevComments: CommentType[] | ReplyType[],
  reply: ReplyType,
  replyToId: string,
  rollback?: boolean,
  replyId?: string
): CommentType[] | ReplyType[] => {
  return prevComments.map((c: CommentType | ReplyType) => {
    if (!replyToId.includes(c.id)) {
      return c;
    } else if (c.id !== replyToId) {
      return { ...c, replies: insertComments(c.replies, reply, replyToId, rollback, replyId) as ReplyType[] };
    } else {
      if (!rollback) {
        if (replyId) {
          // if we provide a replyId then we update else we create / insert
          return { ...c, replies: c.replies.map((r: ReplyType) => (r.id === replyId ? reply : r)) };
        }
        return { ...c, replies: [...c.replies, reply] };
      } else {
        return { ...c, replies: removeReply(c.replies, reply) };
      }
    }
  });
};

const numerosity = (number: number): string => {
  return number > 1 ? 's' : '';
};

export const getProperCommentCreationText = (nowMillisecs: number, creationDateMillisecs: number): string => {
  const now = moment(nowMillisecs);
  const creationDate = moment(creationDateMillisecs);

  const diffInMins = now.diff(creationDate, 'minutes');
  const diffInHours = now.diff(creationDate, 'hours');
  const diffInDays = now.diff(creationDate, 'days');
  const diffInMonths = now.diff(creationDate, 'months');
  const diffInYears = now.diff(creationDate, 'years');

  if (diffInYears) return `${diffInYears} year${numerosity(diffInYears)} ago`;
  if (diffInMonths) return `${diffInMonths} month${numerosity(diffInMonths)} ago`;
  if (diffInDays) return `${diffInDays} day${numerosity(diffInDays)} ago`;
  if (diffInHours) return `${diffInHours} hour${numerosity(diffInHours)} ago`;
  if (diffInMins) return diffInMins >= 5 ? `${diffInMins} mins ago` : 'Moments ago';

  return 'Moments ago';
};
