import DeviceDetector, { DeviceDetectorResult } from 'device-detector-js';

/**
 * Add css class to the top elements to detect device type and other device info
 */
const toCssClassNameString = () => {
  const dd = new DeviceDetector();
  const device: DeviceDetectorResult = dd.parse(window.navigator.userAgent);
  return `${device.os?.name.toLowerCase()} ${device.os?.platform} ${device.device?.type.toLowerCase()}`;
};

export default toCssClassNameString;
