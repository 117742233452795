import { NotificationDescriptor, NotificationType } from '@powtoon/plugin-lib';
import { v1 as generateRandomId } from 'uuid';
import moment from 'moment';

import { Comment } from '~/shared/recoil';
import { newCommentEmail } from '~/shared/events/mail';
import { strippedRichText } from '~/shared/helpers/CommentHelper';
/**
 * Comment Events Lookup
 */

export const COMMENT_EVENTS = {
  COMMENT_CREATED: 'commenting.comment' as NotificationType,
  REPLY_CREATED: 'commenting.reply' as NotificationType,
  COMMENT_MENTION: 'commenting.mention' as NotificationType,
};

/**
 *
 * Get Email Body Template
 * @param event
 * @returns string
 */
export const getEmailBodyTemplate = (
  event: NotificationType,
  author: { id: string; name: string; image?: string },
  comment?: Comment
): { header: string; body: string; twit: string } | undefined => {
  const commentText = comment?.richText || '';
  const strippedComment = strippedRichText(commentText, 300);
  const commentUtcTime = moment.utc(comment?.createdAt ?? '').format('dddd, HH:mm');

  switch (event) {
    case COMMENT_EVENTS.COMMENT_CREATED:
      return {
        header: 'You have a new comment on Powtoon {{powtoon.title}}',
        twit: 'You have a new comment on <em>{{powtoon.title}}</em>',
        body: newCommentEmail({
          user: {
            image: author.image?.replace('format=svg', 'format=png') || '',
            name: author.name,
          },
          comment: { createdAt: commentUtcTime, text: strippedComment },
        }),
      };
    case COMMENT_EVENTS.REPLY_CREATED:
      return {
        header: 'You have a new comment on Powtoon {{powtoon.title}}',
        twit: 'You have a new comment on <em>{{powtoon.title}}</em>',
        body: newCommentEmail({
          user: {
            image: author.image?.replace('format=svg', 'format=png') || '',
            name: author.name,
          },
          comment: { createdAt: commentUtcTime, text: strippedComment },
        }),
      };
    case COMMENT_EVENTS.COMMENT_MENTION:
      return {
        header: 'You have a new comment on Powtoon {{powtoon.title}}',
        twit: 'You were mentioned in a comment to Powtoon with title <em>{{powtoon.title}}</em>',
        body: newCommentEmail({
          user: {
            image: author.image?.replace('format=svg', 'format=png') || '',
            name: author.name,
          },
          comment: { createdAt: commentUtcTime, text: strippedComment },
        }),
      };
  }
};

export const prepareNotification = (
  event: NotificationType,
  comment: Comment,
  slideId: string,
  user: { id: string; name: string; image?: string },
  recipients?: string[] | Set<string>,
  notificationId?: string,
  slideMillisec?: number
): NotificationDescriptor => {
  return {
    notificationId: notificationId || comment.id,
    eventId: generateRandomId(),
    notificationType: event,
    commenterId: user.id,
    commentTarget: { type: 'Slide', id: slideId, slideMillisec: slideMillisec },
    recipients,
    ...getEmailBodyTemplate(event, user, comment),
  };
};
