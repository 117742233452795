const comments = {
  OPEN: {
    MESSAGE: 'No open comments right now.',
  },
  RESOLVED: {
    MESSAGE: 'No resolved comments right now.',
  },
  UNREAD: {
    MESSAGE: 'No unread comments right now.',
  },
  MENTIONED: {
    MESSAGE: 'No mentions right now.',
  },
};

export default comments;
