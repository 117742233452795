import { createContext } from 'react';

export type LogType = {
  appName?: 'commenting-studio';
  appVersion?: string;
  appLevel?: string;
  type?: 'plugins';

  logLevel?: 'error' | 'warn' | 'log' | 'info' | 'debug'; // (error and warn go to both, other to logzIO only)
  userAgent?: string;
  userId?: string;
  powtoonId?: string;
  message?: string;
  milliSecondsSinceStartedTask?: number;
};

export const LoggerContext = createContext({
  log: (message: string | { [key: string]: any }) => {
    window.console.log(message);
  },
});
