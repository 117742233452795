import { orderBy } from 'lodash';

import { Comment, Slide, Reply } from '~/shared/recoil';

export const updateCommentWithSlidePosition = (comment: Comment, slides: Slide[]) => {
  const slideIdx = slides.findIndex(({ id }) => id === comment.slideId);
  return {
    ...comment,
    position: slideIdx === -1 ? slideIdx : slideIdx + 1,
  };
};

export const updateAndOrderComments = (comments: Comment[], slides: Slide[]) => {
  const updatedComments = comments.map(comment => updateCommentWithSlidePosition(comment, slides));
  return orderBy(updatedComments, ['position', 'slideMillisec', 'createdAt']);
};

export const orderComments = (comments: Comment[], slides: Slide[]): Comment[] =>
  updateAndOrderComments(comments, slides);

export const toComment = (comment: any): Comment => ({
  id: comment.id,
  richText: comment.richText,
  createdAt: comment.createdAt,
  powtoonId: comment.powtoonId,
  slideId: comment.slideId,
  slideMillisec: comment.slideMillisec,
  isRead: comment.isRead,
  resolvedAt: comment.resolvedAt || null,
  replies: [],
  userId: comment.userId || undefined,
});

export const toReply = (reply: any): Reply => ({
  id: reply.id,
  richText: reply.richText,
  createdAt: reply.createdAt,
  replies: [],
  userId: reply.userId || undefined,
});

export const compareFncById = (c1: Comment | Reply, c2: Comment | Reply, isDesc: boolean): number => {
  const multiplier = isDesc ? 1 : -1;
  if (c1.id.length > c2.id.length) return -1 * multiplier;
  if (c1.id.length < c2.id.length) return 1 * multiplier;
  return 0;
};

export const isItsChildReply = (id1: string, id2: string): boolean => {
  return id2.includes(id1) && id1 !== id2 && id1.split('#').length + 2 === id2.split('#').length;
};

export const getCommentsRepliesStructured = (comment: Comment): Reply[] => {
  const currentReplies = comment.replies.map((r: Reply) => ({ ...r }));
  currentReplies.sort((r1: Reply, r2: Reply) => compareFncById(r1, r2, true));

  for (let j = 0; j < currentReplies.length; j++) {
    const updatedReply = {
      ...currentReplies[j],
      replies: currentReplies.filter((r2: Reply) => isItsChildReply(currentReplies[j].id, r2.id)),
    };
    currentReplies[j] = updatedReply;
  }
  return currentReplies.filter((r: Reply) => r.id.split('#').length == 4);
};

export const getParentId = (replyId: string) => {
  const splitId = replyId.split('#');
  return splitId.slice(0, splitId.length - 2).join('#');
};

export const strippedRichText = (commentRichText: string, maxChars = 0): string => {
  let strippedText = commentRichText
    // Remove HTML tags
    .replace(/<[^>]*>/g, '')
    // Remove setext-style headers
    .replace(/^[=\-]{2,}\s*$/g, '')
    // Remove footnotes?
    .replace(/\[\^.+?\](\: .*?$)?/g, '')
    .replace(/\s{0,2}\[.*?\]: .*?$/g, '')
    // Remove inline links
    .replace(/\[(.*?)\][\[\(].*?[\]\)]/g, '$1')
    // Remove blockquote
    .replace(/^\s{0,3}>\s?/g, '')
    // Remove reference-style links?
    .replace(/^\s{1,2}\[(.*?)\]: (\S+)( ".*?")?\s*$/g, '')
    // Remove atx-style headers
    .replace(/^(\n)?\s{0,}#{1,6}\s+| {0,}(\n)?\s{0,}#{0,} {0,}(\n)?\s{0,}$/gm, '$1$2$3')
    // Remove emphasis (repeat the line to remove double emphasis)
    .replace(/([\*_]{1,3})(\S.*?\S{0,1})\1/g, '$2')
    .replace(/([\*_]{1,3})(\S.*?\S{0,1})\1/g, '$2')
    // Remove code blocks
    .replace(/(`{3,})(.*?)\1/gm, '$2')
    // Remove inline code
    .replace(/`(.+?)`/g, '$1')
    // Replace two or more newlines with exactly two? Not entirely sure this belongs here...
    .replace(/\n{2,}/g, '\n')
    // Remove inline links
    .replace(/\[(.*?)\][\[\(].*?[\]\)]/g, '$1');

  if (maxChars > 0 && strippedText.length > maxChars) {
    strippedText = `${strippedText.slice(0, maxChars)}...`;
  }
  strippedText = strippedText.replace(/(@[a-zA-Z]+ [a-zA-Z]+)/g, '<b>$1</b>');
  strippedText = strippedText.replaceAll('\n', '<br />');

  return strippedText;
};
