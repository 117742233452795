import React, { useRef, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal';
import { ApolloProvider, ApolloClient, InMemoryCache, from } from '@apollo/client';
import { RecoilRoot } from 'recoil';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { PowtoonStudio, powtoonStudioFromManifest, ZoidCoreManifest } from '@powtoon/plugin-lib';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import { onError } from '@apollo/client/link/error';

import { useBITracking } from '~/shared/hooks/useTracking';
import { awsConfig } from '~/shared/config/aws.config';
import { appConfig } from '~/shared/config/app.config';
import theme, { GlobalStyle } from '~/shared/theme';
import { getPowtoonAccessToken } from '~/shared/jwt';
import { LoggerContext, LogType } from '~/shared/contexts/LoggerContext';
import LogzioLogger from '~/shared/utils/LogzioLogger';
import { fetchPowtoonAccessToken } from '~/shared/helpers/AuthHelper';

import Shell from './Shell';

const LogConfig: LogType = {
  appLevel: appConfig.logzioAppLevel || 'prod',
  appName: 'commenting-studio',
  appVersion: appConfig.logzioAppVersion || '1.0.0',
  userAgent: window.navigator.userAgent,
  type: 'plugins',
};

const logzioLogger = appConfig.logzioToken
  ? new LogzioLogger({
      defaultData: LogConfig,
      token: appConfig.logzioToken,
      url: appConfig.logzioUrl,
    })
  : {
      log: (message: string | LogType) => {
        const data = typeof message === 'object' ? message : { message: message };
        window.console.log({ ...LogConfig, ...data });
      },
    };

const history = createBrowserHistory();
if (appConfig.SentryDSN) {
  Sentry.init({
    dsn: appConfig.SentryDSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
  });
}

// temporary // valid for 60 days
const setTokenLink = setContext((_request, { headers }) => {
  const token = getPowtoonAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

//@TODO move it to the internal stage, after we initialize the application
const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      logzioLogger.log({
        logLevel: 'error',
        message: `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      })
    );
  if (networkError)
    logzioLogger.log({
      logLevel: 'error',
      message: `[Network error]: ${networkError}`,
    });
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([onErrorLink, createAuthLink(awsConfig).concat(setTokenLink), createSubscriptionHandshakeLink(awsConfig)]),
});

const manifest = new (class Manifest extends ZoidCoreManifest {
  tag = 'powtoon-comments-plugin';
  url = new URL(location.href).origin;
})();

function AppContent({ studio }: { studio: any }) {
  const [isReady, setReady] = useState(false);
  const { BIEventTracker } = useBITracking({
    trackingDSN: appConfig.PowtoonTrackingDSN,
  });
  const studioRef = useRef(studio);

  React.useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    const init = async () => {
      await fetchPowtoonAccessToken(studio!);
      timer = setTimeout(() => {
        setReady(true);
      }, 500);
    };
    init();

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  if (!isReady) return <span>Loading...</span>;

  return (
    <Router history={history}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <LoggerContext.Provider value={logzioLogger}>
              <GlobalStyle />
              <BIEventTracker>
                <Shell studio={studioRef.current} />
              </BIEventTracker>
            </LoggerContext.Provider>
          </ModalProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Router>
  );
}

function App() {
  const studio = useRef<PowtoonStudio>();
  try {
    studio.current = powtoonStudioFromManifest(manifest);
  } catch (e) {
    if (window.self !== window.top) {
      logzioLogger.log({ logLevel: 'warn', message: 'Commenting app was loaded with wrong zoid context' });
      return null;
    }

    return <span>APP should not be loaded standalone</span>;
  }

  return (
    <RecoilRoot>
      <AppContent studio={studio.current} />
    </RecoilRoot>
  );
}

export default App;
