import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { convertToRaw, EditorState } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';

import ButtonsPanel from '~/shared/components/ButtonsPanel';
import RichTextEditor from '~/shared/components/editor/RichTextEditor';
import { MentionData } from '~/shared/recoil';

const Root = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const ReplyInputButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const FormContainer = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div``;

const ErrorMessage = styled.span`
  width: 100%;
  color: ${({ theme }) => theme.colors.error};
`;

const RichTextForm = ({
  onOkButtonClick,
  okButtonText,
  mentionSuggestion,
  onCancelButtonClick,
  onSubmit,
}: {
  onOkButtonClick: () => void;
  okButtonText: string;
  onCancelButtonClick: () => void;
  onSubmit: (val: string, mentions: any[]) => void;
  mentionSuggestion: MentionData[];
}) => {
  const [inputValue, setInputValue] = useState(() => EditorState.createEmpty());
  const [errorText, setErrorText] = useState('');

  const handleChange = useCallback(
    (e: EditorState, n: MentionData[]) => {
      const newMarkdownText = draftToMarkdown(convertToRaw(e.getCurrentContent()), {
        entityItems: {
          mention: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            open: function (entity) {
              // @ts-ignore
              return `[`;
            },
            close: function (entity) {
              // @ts-ignore
              return `](#mention-${entity.data.mention.id})`;
            },
          },
        },
      });
      setInputValue(e);
      //  Save as markdown
      onSubmit(newMarkdownText, n);
    },
    [onSubmit]
  );

  const handleSend = useCallback(() => {
    if (!inputValue.getCurrentContent().hasText()) {
      setErrorText('Enter message content to send');
    } else {
      onOkButtonClick();
      setErrorText('');
    }
  }, [inputValue, setErrorText, onOkButtonClick]);

  const onClick = () => {
    setErrorText('');
  };

  return (
    <Root>
      <FormContainer>
        <InputWrapper>
          <RichTextEditor
            onChange={handleChange}
            onClick={onClick}
            handleSend={handleSend}
            isReply={true}
            mentionSuggestions={mentionSuggestion}
          />
          <ErrorMessage>{errorText}</ErrorMessage>
        </InputWrapper>
        <ReplyInputButtonsWrapper>
          <ButtonsPanel
            onCancelButtonClick={onCancelButtonClick}
            onOkButtonClick={handleSend}
            okButtonText={okButtonText}
            disabled={false}
          />
        </ReplyInputButtonsWrapper>
      </FormContainer>
    </Root>
  );
};

export default RichTextForm;
