// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import * as zoid from 'zoid/dist/zoid.frameworks';

import { CoreMessageHandler, CorePromiseMessageHandler } from '../../apis';

export { zoid };

// export interface ZoidManifest {
//   tag: string;
//   url?: string;
//   dimensions: {
//     width: string;
//     height: string;
//   };
//   props: {
//     onMessageFromPlugin: {
//       type: 'function';
//       required: true;
//     };

//     onSetPluginMessageHandler: {
//       type: 'function';
//       required: true;
//     };
//   };
// }

export abstract class ZoidCoreManifest {
  abstract tag: string;
  abstract url: string;

  readonly dimensions = {
    width: '100%',
    height: '100%',
  };
  readonly props = {
    onMessageFromPlugin: {
      type: 'function',
      required: true,
    },
    onPromiseMessageFromPlugin: {
      type: 'function',
      required: true,
    },
    onSetPluginMessageHandler: {
      type: 'function',
      required: true,
    },
  };
}

export interface ZoidCoreProps {
  onMessageFromPlugin: CoreMessageHandler;
  onPromiseMessageFromPlugin: CorePromiseMessageHandler;
  onSetPluginMessageHandler: (handler: CoreMessageHandler) => void;
}
