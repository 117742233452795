import React from 'react';
import { SetterOrUpdater } from 'recoil';
import styled from 'styled-components';

import { Reply as ReplyType, Comment as CommentType } from '~/shared/recoil';
import { getParentId } from '~/shared/helpers/CommentHelper';
import useReplyActions from '~/shared/hooks/useReplyActions';
import { insertComments } from '~/shared/utils';
import { findAuthorById } from '~/shared/helpers/UserHelper';
import useHostStateData from '~/shared/hooks/useHostStateData';
import CommentsFilterType from '~/shared/types/CommentsFilterType';

import Reply from './Reply';

const Root = styled.div``;

const RepliesList = ({
  replies,
  removeReply,
  setComments,
  currentFilter,
  refreshPowtoonAccessToken,
  slideId,
}: {
  currentFilter: CommentsFilterType;
  replies: ReplyType[];
  removeReply: (reply: ReplyType) => void;
  setComments?: SetterOrUpdater<CommentType[]>;
  refreshPowtoonAccessToken: () => void;
  slideId: string;
}) => {
  const { deleteReplyOnServer } = useReplyActions();
  const [hostDataState] = useHostStateData();

  const deleteWithTokenRefresher = async (next: any, args: any, tries = 1) => {
    for (let i = tries; i >= 0; i--) {
      try {
        const data = await next(args);
        return data;
      } catch (error) {
        await refreshPowtoonAccessToken();
      }
    }
  };

  const deleteReply = async (reply: ReplyType) => {
    removeReply(reply);
    try {
      const result = await deleteWithTokenRefresher(deleteReplyOnServer, { variables: { id: reply.id } }, 1);
      if (result.data.removeReply) {
        return;
      }
    } catch (err) {}

    // rollback delete
    if (setComments) {
      const replyToId = getParentId(reply.id);
      setComments((prevComments: CommentType[]) => insertComments(prevComments, reply, replyToId) as CommentType[]);
    }
  };

  return (
    <Root data-cy="replies-list">
      {replies.map(reply => (
        <Reply
          item={{
            id: reply.id,
            slideId,
            text: reply.richText,
            createdAt: reply.createdAt,
            authorId: reply.userId,
          }}
          replies={reply.replies}
          isMoreButton={reply.userId === (hostDataState.currentUser?.userId as string)}
          key={reply.id}
          user={findAuthorById(reply.userId!, hostDataState.powtoonUsers)}
          isRepliesEnabled={false}
          onDeleteButtonClick={() => deleteReply(reply)}
          setComments={setComments}
          refreshPowtoonAccessToken={refreshPowtoonAccessToken}
          currentFilter={currentFilter}
        />
      ))}
    </Root>
  );
};

export default RepliesList;
