import { gql, useMutation } from '@apollo/client';

const useCommentActions = ({ selectedSlideId, powtoonId }: { selectedSlideId?: string; powtoonId?: string }) => {
  const [addComment, { loading: addingLoading, error: addingError }] = useMutation(gql`
    mutation ($powtoonId: String!, $richText: String!, $slideId: String!, $slideMillisec: Int!) {
      addComment(powtoonId: $powtoonId, richText: $richText, slideId: $slideId, slideMillisec: $slideMillisec)
    }
  `);

  const [deleteComment, { loading: deletingLoading, error: deletingError }] = useMutation(
    gql`
      mutation ($id: String!) {
        removeComment(id: $id)
      }
    `
  );

  const [mutateCommentsTime, { loading: updatingLoading, error: updatingError }] = useMutation<Record<string, any>>(
    gql`
      mutation ($powtoonId: String!, $slideId: String!, $start: Int!, $increment: Int!) {
        updateCommentsTime(
          powtoonId: $powtoonId
          slideId: $slideId
          startMillisec: $start
          incrementMillisec: $increment
        )
      }
    `,
    {
      variables: {
        slideId: selectedSlideId,
        powtoonId,
      },
    }
  );

  const [updateCommentText, { loading: updatingTextLoading, error: updatingTextError }] = useMutation(
    gql`
      mutation ($id: ID!, $richText: String!) {
        updateCommentText(id: $id, richText: $richText)
      }
    `
  );

  const [resolveComment, { loading: resolvingCommentLoading, error: resolveCommentError }] = useMutation(
    gql`
      mutation ($id: ID!, $status: Boolean) {
        resolveComment(id: $id, status: $status)
      }
    `
  );

  const [updateCommentReadStatus, { loading: commentStatusLoading, error: commentStatusError }] = useMutation(
    gql`
      mutation ($id: ID!, $richText: String!) {
        updateCommentText(id: $id, richText: $richText)
      }
    `
  );

  return {
    addComment,
    deleteComment,
    mutateCommentsTime,
    updateCommentText,
    resolveComment,
    updateCommentReadStatus,
    loading:
      addingLoading ||
      deletingLoading ||
      updatingLoading ||
      updatingTextLoading ||
      resolvingCommentLoading ||
      commentStatusLoading,
    error:
      addingError || updatingError || deletingError || updatingTextError || resolveCommentError || commentStatusError,
  };
};

export default useCommentActions;
