import { decode } from 'jsonwebtoken';
import store from 'store';
import { PowtoonAccessToken } from '@powtoon/plugin-lib';

import { appConfig } from '~/shared/config/app.config';

const POWTOON_AUTH_SECRET = { json: true };
export const POWTOON_ACCESS_TOKEN = appConfig.JWTCookieName;

export function getPowtoonAccessToken() {
  if (!store.enabled) {
    return null;
  }
  return store.get(POWTOON_ACCESS_TOKEN, null);
}

export function getUser() {
  const token = getPowtoonAccessToken();
  return parseTokensPayload(token);
}

export function setPowtoonAccessToken(token: string) {
  if (store.enabled) {
    store.set(POWTOON_ACCESS_TOKEN, token);
  }
}

export function parseTokensPayload(token: string): PowtoonAccessToken {
  return decode(token, POWTOON_AUTH_SECRET) as PowtoonAccessToken;
}

export function getTokensPayload(powtoonAccessToken: string): PowtoonAccessToken {
  return decode(powtoonAccessToken, POWTOON_AUTH_SECRET) as PowtoonAccessToken;
}

export function decodePowtoonAccessToken(powtoonAccessToken: string): boolean {
  try {
    decode(powtoonAccessToken, POWTOON_AUTH_SECRET);
    return true;
  } catch (err) {
    window.console.log(err);
  }
  return false;
}
