import { AUTH_TYPE } from 'aws-appsync-auth-link';
import { createAuthLink } from 'aws-appsync-auth-link';

export const awsConfig: Parameters<typeof createAuthLink>[0] = {
  url: process.env.REACT_APP_AWS_ENDPOINT!,
  region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: process.env.REACT_APP_AWS_APPSYNC_KEY!,
  },
};
