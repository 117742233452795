import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import React from 'react';
import styled from 'styled-components';
import { convertToHTML } from 'draft-convert';
import { Markup } from 'interweave';
import { Text } from '@powtoon/design-system-components';

import { MentionData } from '~/shared/recoil';

import RichTextEditor from '../editor/RichTextEditor';
import ButtonsPanel from '../ButtonsPanel';

const ReplyInputButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const InlineCommentWrapper = styled.span`
  span > p:first-of-type {
    display: inline;
  }

  .comment-mention-link {
    display: inline;
    font-weight: bold;
  }
`;

interface IProps {
  markdownText: string;
  readOnly?: boolean;
  mentionSuggestions: MentionData[];
  slideId: string;
  onUpdateButtonClick: (rickText: string, slideId: string, mentions?: any[]) => void;
  onCancelButtonClick: () => void;
}

/**
 * A view only component to render complex markdown based comments,
 * it uses the Draft Js (Read only mode) to render content
 *
 * @returns React.Component
 *
 */
export default class CommentTextView extends React.Component<IProps> {
  onChange: (editorState: EditorState, newMentions: any[]) => void;
  state = { editorState: EditorState.createEmpty(), finalState: EditorState.createEmpty(), newMentions: [] };
  constructor(props: IProps) {
    super(props);

    // Convert input from markdown to draft-js state
    const markdownText: string = this.props.markdownText;
    const rawData = markdownToDraft(markdownText?.replace(/\\n\\n/gm, ' \n '), {
      blockEntities: {
        mention_open: function (item) {
          return {
            type: 'mention',
            mutability: 'IMMUTABLE',
            data: {
              mention: {
                // @ts-ignore
                id: item.id,
              },
            },
          };
        },
      },
    });
    const contentState = convertFromRaw(rawData);
    const newEditorState = EditorState.createWithContent(contentState);
    this.state = {
      editorState: newEditorState,
      finalState: EditorState.createEmpty(),
      newMentions: [],
    };

    this.saveCommentText = this.saveCommentText.bind(this);

    this.onChange = (finalState: EditorState, newMentions: MentionData[]): any => {
      this.setState({ finalState, newMentions });
    };
  }

  saveCommentText = (): void => {
    this.props.onUpdateButtonClick(
      draftToMarkdown(convertToRaw(this.state.finalState?.getCurrentContent()), {
        entityItems: {
          mention: {
            open: function () {
              // @ts-ignore
              return `[`;
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            close: function (entity) {
              // @ts-ignore
              return `](#mention-${entity.data.mention.id})`;
            },
          },
        },
      }),
      this.props.slideId,
      this.state.newMentions
    );
    this.setState({ editorState: this.state.finalState });
  };

  getHtmlContent = () => {
    return convertToHTML({
      blockToHTML: (block: any) => {
        if (block.type === 'unstyled') {
          return <Text fontStyle="medium" />;
        }
      },
      entityToHTML: (entity: any, originalText: any) => {
        if (entity.type === 'LINK') {
          return (
            <Text fontStyle="medium" className="comment-mention-link" data-mention={entity.data.url}>
              {originalText}
            </Text>
          );
        }
        return originalText;
      },
    })(this.state.editorState.getCurrentContent());
  };

  render() {
    return (
      <span>
        {this.props.readOnly && (
          <InlineCommentWrapper className="inline-comment-wrapper">
            <Markup
              allowElements={true}
              containerTagName={'span'}
              disableLineBreaks={false}
              tagName={'span'}
              content={this.getHtmlContent()}
            />
          </InlineCommentWrapper>
        )}
        {!this.props.readOnly && (
          <RichTextEditor
            defaultState={this.state.editorState}
            readOnly={this.props.readOnly}
            onChange={this.onChange}
            mentionSuggestions={this.props.mentionSuggestions}
          />
        )}
        {!this.props.readOnly && (
          <ReplyInputButtonsWrapper>
            <ButtonsPanel
              onCancelButtonClick={this.props.onCancelButtonClick}
              onOkButtonClick={this.saveCommentText}
              okButtonText={'Update'}
              disabled={false}
            />
          </ReplyInputButtonsWrapper>
        )}
      </span>
    );
  }
}
