import React, { ReactNode, useCallback, useContext } from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { SetterOrUpdater } from 'recoil';
import { NotificationDescriptor } from '@powtoon/plugin-lib';
import { v1 as generateRandomId } from 'uuid';
import moment from 'moment';

import { useBITracking } from '~/shared/hooks/useTracking';
import { Comment, Reply } from '~/shared/recoil';
import useCommentActions from '~/shared/hooks/useCommentActions';
import useHostStateData from '~/shared/hooks/useHostStateData';
import CommentsFilterType from '~/shared/types/CommentsFilterType';
import { COMMENT_EVENTS, prepareNotification } from '~/shared/events/CommentEvents';
import { IsAllowed, IsNotAllowed, isActionAllowed } from '~/shared/components/Acl/Abilities';
import { Abilities } from '~/shared/helpers/PermissionHelper';
import EmptyStates from '~/shared/constants/comments';
import { findAuthorById } from '~/shared/helpers/UserHelper';
import { LoggerContext } from '~/shared/contexts/LoggerContext';

import RichTextForm from './RichTextForm';
import CommentsList from './CommentsList';
import CommentsListHeader from './CommentsListHeader';
import EmptyComments from './EmptyComments';
import EmptyCommentsGray from './EmptyCommentsGray';
import Disconnected from './Disconnected';

const FormWrapper = styled.div`
  position: absolute;
  bottom: 0;
  top: auto;
  min-height: 183px;
  height: auto;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

const CommentsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

const CommentsLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 22%;
  margin-right: 22%;
  text-align: center;
  color: ${({ theme }) => theme.colors.textLight};
  font-weight: 500;
`;
interface Props {
  comments: Comment[];
  loading?: boolean;
  optimisticUpdateInsert: (data: Comment, rollback?: boolean) => void;
  optimisticUpdateRemove: (data: Comment, rollback?: boolean) => void;
  setClientComments: SetterOrUpdater<Comment[]>;
  currentFilter: CommentsFilterType;
  setCurrentFilter: SetterOrUpdater<CommentsFilterType>;
  sendNotification: (event: NotificationDescriptor) => any | void;
  refreshPowtoonAccessToken: () => void;
  onInviteBtnClick: () => void;
  onCommentClick: (data: Comment) => void;
  disableFilters?: boolean;
}

export default function Comments({
  comments,
  loading,
  optimisticUpdateInsert,
  optimisticUpdateRemove,
  setClientComments,
  currentFilter,
  setCurrentFilter,
  sendNotification,
  refreshPowtoonAccessToken,
  onInviteBtnClick,
  onCommentClick,
  disableFilters,
}: Props) {
  const logger = useContext(LoggerContext);
  const [hostDataState, hostDataActions] = useHostStateData();
  const { addComment, deleteComment, updateCommentText, resolveComment, updateCommentReadStatus } = useCommentActions({
    powtoonId: hostDataState.powtoonId,
    selectedSlideId: hostDataState.selectedSlideId,
  });

  const { track } = useBITracking({});

  const extractMentionedUsersIds = (richText: string, exceptId: string) => {
    return richText
      .match(/\((#mention-[^\s]+)\)/gim)
      ?.map(mention => mention.match(/\d+/gim)?.pop())
      .filter(userId => userId != exceptId);
  };

  const isOwner = (): boolean => {
    return (
      hostDataState.powtoonOwner &&
      hostDataState.currentUser &&
      hostDataState.powtoonOwner.id == hostDataState.currentUser.userId
    );
  };

  const fetchWithTokenRefresher = async (next: any, args: any, tries = 1) => {
    for (let i = tries; i >= 0; i--) {
      try {
        const data = await next(args);
        return data;
      } catch (error) {
        await refreshPowtoonAccessToken();
      }
    }
  };

  const onUserMention = async (data: Comment, mentions: string[], transactionId: string) => {
    sendNotification(
      prepareNotification(
        COMMENT_EVENTS.COMMENT_MENTION,
        data,
        hostDataState.selectedSlideId || '',
        findAuthorById(hostDataState.currentUser?.userId, hostDataState.powtoonUsers),
        mentions,
        transactionId
      )
    );
  };

  const onAddComment = useCallback(
    async (richText: string) => {
      const canAddComment = isActionAllowed(Abilities.ADD_COMMENT, hostDataState.currentUser?.permissions);
      if (hostDataState.powtoonId && hostDataState.selectedSlideId && canAddComment) {
        const randomId = `${Math.floor(Math.random() * 23454666) + 1}`;
        const transactionId = generateRandomId();
        const newMentions = extractMentionedUsersIds(richText, hostDataState.currentUser?.userId);
        const powtoonOwner = hostDataState.powtoonOwner!;
        const data = {
          id: randomId,
          richText,
          slideMillisec: hostDataState.timelineStart,
          slideId: hostDataState.selectedSlideId,
          powtoonId: hostDataState.powtoonId,
          createdAt: '',
          replies: [],
        };

        optimisticUpdateInsert({
          ...data,
          userId: hostDataState.currentUser?.userId,
          createdAt: new Date().toISOString(),
        });

        hostDataActions.setSelectedCommentId(randomId);

        try {
          logger.log({
            logLevel: 'info',
            powtoonId: hostDataState.powtoonId,
            message: `new comment created at ${new Date()}`,
            userId: hostDataState.currentUser?.userId,
          });
          const startTime = moment();
          const resp = await fetchWithTokenRefresher(addComment, { variables: data }, 1);
          logger.log({
            logLevel: 'info',
            powtoonId: hostDataState.powtoonId,
            message: `new comment saved successfully at ${new Date()}`,
            userId: hostDataState.currentUser?.userId,
            milliSecondsSinceStartedTask: moment().diff(startTime),
          });
          if (resp.data.addComment) {
            const commentId: string = resp.data.addComment;
            track({
              action: 'post',
              label: 'post',
              value: '10331',
              extra: { richText, id: commentId, slideId: data.slideId },
            });
            setClientComments((prevComments: Comment[]) =>
              prevComments.map(c => (c.id === randomId ? ({ ...c, id: commentId } as Comment) : c))
            );
            if (newMentions) {
              onUserMention(
                { ...data, id: commentId, createdAt: new Date().toISOString() } as Comment,
                newMentions as [string],
                transactionId
              );

              if (newMentions.includes(hostDataState.powtoonOwner?.id)) return;
            }
            if (hostDataState.powtoonOwner && !isOwner())
              sendNotification(
                prepareNotification(
                  COMMENT_EVENTS.COMMENT_CREATED,
                  { ...data, id: commentId, createdAt: new Date().toISOString() } as Comment,
                  hostDataState.selectedSlideId,
                  findAuthorById(hostDataState.currentUser?.userId, hostDataState.powtoonUsers),
                  [powtoonOwner.id!],
                  transactionId
                )
              );
          }
        } catch (error) {
          // console.log('posting a comment error', JSON.stringify(error));
        }

        // rollback
        optimisticUpdateInsert(data, true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hostDataState.timelineStart,
      addComment,
      hostDataState.selectedSlideId,
      hostDataState.powtoonId,
      optimisticUpdateInsert,
      setClientComments,
      hostDataState.powtoonOwner,
    ]
  );

  const onCreateReply = (reply: Reply, commentId: string) => {
    const transactionId = generateRandomId();
    const parentComment = comments.find(c => c.id === commentId);
    const powtoonOwner = hostDataState.powtoonOwner!;
    let shouldNotifyCommentAuthor =
      parentComment?.userId && parentComment?.userId !== hostDataState.currentUser?.userId;

    let shouldNotifyPowtoonOwner =
      !isOwner() &&
      parentComment?.userId &&
      hostDataState.powtoonOwner?.id &&
      parentComment?.userId !== hostDataState.powtoonOwner?.id;

    const newMentions = extractMentionedUsersIds(reply.richText, hostDataState.currentUser?.userId);

    logger.log({
      logLevel: 'info',
      powtoonId: hostDataState.powtoonId,
      message: `new reply to comment ${commentId} created at ${new Date()}`,
      userId: reply.userId,
    });

    if (newMentions) {
      onUserMention(
        { ...reply, id: commentId, createdAt: new Date().toISOString() } as Comment,
        newMentions as [string],
        transactionId
      );

      shouldNotifyCommentAuthor = shouldNotifyCommentAuthor && !newMentions.includes(parentComment?.userId);
      shouldNotifyPowtoonOwner = shouldNotifyPowtoonOwner && !newMentions.includes(hostDataState.powtoonOwner?.id);
    }

    if (parentComment?.userId && shouldNotifyCommentAuthor)
      sendNotification(
        prepareNotification(
          COMMENT_EVENTS.REPLY_CREATED,
          { ...reply, id: commentId, createdAt: new Date().toISOString() } as Comment,
          hostDataState.selectedSlideId!,
          findAuthorById(hostDataState.currentUser?.userId, hostDataState.powtoonUsers),
          [parentComment.userId!],
          transactionId
        )
      );

    if (hostDataState.powtoonOwner && shouldNotifyPowtoonOwner)
      sendNotification(
        prepareNotification(
          COMMENT_EVENTS.REPLY_CREATED,
          { ...reply, id: commentId, createdAt: new Date().toISOString() } as Comment,
          hostDataState.selectedSlideId!,
          findAuthorById(hostDataState.currentUser?.userId, hostDataState.powtoonUsers),
          [powtoonOwner.id!],
          transactionId
        )
      );
  };

  const onDeleteComment = useCallback(
    async (id: string) => {
      const commentToRemove = comments.find(c => c.id === id);
      const isCommentAuthor = commentToRemove?.userId == hostDataState.currentUser?.userId;
      const canRemoveComment =
        isCommentAuthor && isActionAllowed(Abilities.ADD_COMMENT, hostDataState.currentUser?.permissions);

      if (commentToRemove && canRemoveComment) {
        optimisticUpdateRemove(commentToRemove);

        try {
          const startTime = moment();
          const resp = await fetchWithTokenRefresher(deleteComment, { variables: { id } }, 1);
          if (resp.data.removeComment) {
            logger.log({
              logLevel: 'info',
              powtoonId: hostDataState.powtoonId,
              message: `User removed comment ${id} at ${new Date()}`,
              userId: hostDataState.currentUser?.userId,
              milliSecondsSinceStartedTask: moment().diff(startTime),
            });

            return;
          }
        } catch (err) {}

        // rollback
        optimisticUpdateRemove(commentToRemove, true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteComment, optimisticUpdateRemove, comments, hostDataState.currentUser]
  );

  /**
   * Update comment text
   * @param id String
   * @param richText String
   * @returns Promise
   */
  const onUpdateComment = async (id: string, richText: string, slideId: string, mentions?: any[]) => {
    const commentToUpdate = comments.find(c => c.id === id);
    const transactionId = generateRandomId();
    const isCommentAuthor = commentToUpdate?.userId == hostDataState.currentUser?.userId;
    const canEditComment =
      isCommentAuthor && isActionAllowed(Abilities.ADD_COMMENT, hostDataState.currentUser?.permissions);
    if (canEditComment) {
      try {
        const startTime = moment();
        const resp = await fetchWithTokenRefresher(updateCommentText, { variables: { id: id, richText: richText } }, 1);
        const authors = hostDataState.powtoonUsers;
        if (resp.data.updateCommentText) {
          track({
            action: 'post',
            label: 'update',
            value: '10333',
            extra: { richText, id, slideId },
          });

          logger.log({
            logLevel: 'info',
            powtoonId: hostDataState.powtoonId,
            message: `User edited comment ${id} at ${new Date()}`,
            userId: commentToUpdate?.userId,
            milliSecondsSinceStartedTask: moment().diff(startTime),
          });

          if (authors)
            if (mentions?.length) {
              onUserMention(commentToUpdate!, mentions.map(user => user.id) as [string], transactionId);
            }
          return true;
        }
      } catch (err) {}
    }
    return false;
  };

  /**
   * Resolve comment handler
   * @TODO refresh the comments list based on the filters
   * @param id String
   * @returns Promise
   */
  const onResolveComment = async (id: string, status: boolean) => {
    try {
      const resolvedAtValue = status ? new Date().toISOString() : undefined;
      setClientComments((prevComments: Comment[]) =>
        prevComments.map(c => (c.id === id ? ({ ...c, resolvedAt: resolvedAtValue } as Comment) : c))
      );
      const startTime = moment();
      const resp = await fetchWithTokenRefresher(resolveComment, { variables: { id, status } }, 1);
      if (resp.data.resolveComment) {
        logger.log({
          logLevel: 'info',
          powtoonId: hostDataState.powtoonId,
          message: `User ${status ? 'resolved' : 'reopened'} comment ${id} at ${new Date()}`,
          userId: hostDataState.currentUser?.userId,
          milliSecondsSinceStartedTask: moment().diff(startTime),
        });

        return true;
      }
    } catch (err) {}
  };

  const onMarkReadStatusClick = async (id: string, status: boolean) => {
    try {
      setClientComments((prevComments: Comment[]) =>
        prevComments.map(c => (c.id === id ? { ...c, isRead: status } : c))
      );
      const resp = await fetchWithTokenRefresher(updateCommentReadStatus, { variables: { id: id, status: status } }, 1);
      if (resp.data) {
        return true;
      }
    } catch (err) {}
  };

  const getEmptyStateText = (): ReactNode => {
    let extra;
    const permissions = hostDataState.currentUser?.permissions;
    const can_share = permissions ? isActionAllowed(Abilities.SHARE_POWTOON, permissions) : false;

    if (can_share) {
      extra = { onInviteBtnClick };
    }

    switch (currentFilter) {
      case CommentsFilterType.ALL:
        return disableFilters ? (
          <EmptyComments message={EmptyStates.OPEN.MESSAGE} {...extra} />
        ) : (
          <EmptyCommentsGray message={EmptyStates.OPEN.MESSAGE} />
        );
      case CommentsFilterType.RESOLVED:
        return <EmptyCommentsGray message={EmptyStates.RESOLVED.MESSAGE} />;
      case CommentsFilterType.UNREAD:
        return <EmptyCommentsGray message={EmptyStates.UNREAD.MESSAGE} />;
      case CommentsFilterType.MENTIONED:
        return <EmptyCommentsGray message={EmptyStates.MENTIONED.MESSAGE} />;
    }
  };

  const onCommentClickWrapper = (comment: Comment) => {
    hostDataActions.setSelectedCommentId(comment.id);
    onCommentClick(comment);
  };

  return (
    <>
      <IsAllowed to={Abilities.VIEW_COMMENTS} user={hostDataState.currentUser}>
        <>
          <CommentsListHeader
            setCurrentFilter={setCurrentFilter}
            statusFiltersEnabled={!disableFilters}
            focusMode={hostDataState.focusStatus}
          />
          <Disconnected>
            <>
              <CommentsWrapper>
                {loading ? (
                  <CommentsLoadingWrapper>
                    <Loader type="TailSpin" color="#556DFB" height={40} width={40} />
                  </CommentsLoadingWrapper>
                ) : comments.length ? (
                  <CommentsList
                    comments={comments}
                    currentFilter={currentFilter}
                    onUpdateComment={onUpdateComment}
                    onDeleteComment={onDeleteComment}
                    onResolveComment={onResolveComment}
                    onCreateReply={onCreateReply}
                    onCommentClick={onCommentClickWrapper}
                    setClientComments={setClientComments}
                    onMarkReadStatusClick={onMarkReadStatusClick}
                    refreshPowtoonAccessToken={refreshPowtoonAccessToken}
                  />
                ) : (
                  <EmptyStateWrapper>{getEmptyStateText()}</EmptyStateWrapper>
                )}
              </CommentsWrapper>
              <FormWrapper data-cy="form-wrapper">
                {/* <Form onSubmit={onAddComment} /> */}
                <IsAllowed to={Abilities.ADD_COMMENT} user={hostDataState.currentUser}>
                  <RichTextForm
                    onSubmit={onAddComment}
                    currentTime={hostDataState.timelineStart}
                    onInviteBtnClick={onInviteBtnClick}
                  />
                </IsAllowed>
              </FormWrapper>
            </>
          </Disconnected>
        </>
      </IsAllowed>
      <IsNotAllowed to={Abilities.VIEW_COMMENTS} user={hostDataState.currentUser}>
        <CommentsWrapper>
          <EmptyStateWrapper>
            {"We're sorry, you don't have the required permissions to view comments for this Powtoon."}
          </EmptyStateWrapper>
        </CommentsWrapper>
      </IsNotAllowed>
    </>
  );
}
