import * as React from 'react';
import { render } from 'react-dom';

import { App } from './app';

function NeedPluginHost() {
  if (process.env.NODE_ENV !== 'production') {
    location.href = 'host.html';
  }
  return <h1>Needs plugin host</h1>;
}

//  @ts-ignore
render(window.self === window.top && window.Cypress ? <NeedPluginHost /> : <App />, document.getElementById('app'));
