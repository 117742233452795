import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Text } from '@powtoon/design-system-components';

import useHostStateData from '~/shared/hooks/useHostStateData';
import GifPlaceHolder from '~/shared/images/Gif-placeholder.png';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const DisconnectedImg = styled.img`
  width: 175px;
  height: 175px;
  margin-bottom: 10px;
  filter: grayscale(100%);
`;

const DisconnectedText = styled(Text)`
  display: flex;
  text-align: center;
`;

const Disconnected = function ({ children }: { children: JSX.Element }) {
  const [hostDataState, hostDataActions] = useHostStateData();

  const setOnline = () => {
    hostDataActions.setOnlineStatus(true);
  };
  const setOffline = () => {
    hostDataActions.setOnlineStatus(false);
  };

  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  });

  if (hostDataState.onlineStatus) return <>{children}</>;
  return (
    <Root>
      <DisconnectedImg src={GifPlaceHolder} />
      <DisconnectedText>
        Something went wrong. <br /> Try again later
      </DisconnectedText>
    </Root>
  );
};

export default Disconnected;
