import { useRecoilState } from 'recoil';

import {
  powtoonIdState,
  powtoonSlidesState,
  selectedSlideIdState,
  timelineState,
  selectedCommentIdState,
  isMenuActive,
  activeMenu,
  powtoonMentionSuggestion,
  powtoonUsersList,
  currentUserState,
  powtoonOwnerState,
  isAppOnline,
  focusState,
} from '~/shared/recoil';

const useHostStateData = () => {
  const [powtoonId, setPowtoonId] = useRecoilState(powtoonIdState);
  const [slides, setSlides] = useRecoilState(powtoonSlidesState);
  const [selectedSlideId, setSelectedSlideId] = useRecoilState(selectedSlideIdState);
  const [timelineStart, setTimelineStart] = useRecoilState(timelineState);
  const [selectedCommentId, setSelectedCommentId] = useRecoilState(selectedCommentIdState);
  const [activeModal, setActiveModal] = useRecoilState(isMenuActive);
  const [activeModalId, setActiveModalId] = useRecoilState(activeMenu);
  const [mentionSuggestions, setMentionSuggestion] = useRecoilState(powtoonMentionSuggestion);
  const [powtoonUsers, setPowtoonUsers] = useRecoilState(powtoonUsersList);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [powtoonOwner, setPowtoonOwner] = useRecoilState(powtoonOwnerState);
  const [onlineStatus, setOnlineStatus] = useRecoilState(isAppOnline);
  const [focusStatus, setFocusStatus] = useRecoilState(focusState);

  const state = {
    powtoonId,
    slides,
    timelineStart,
    selectedSlideId,
    selectedCommentId,
    activeModal,
    activeModalId,
    mentionSuggestions,
    powtoonUsers,
    currentUser,
    powtoonOwner,
    onlineStatus,
    focusStatus,
  };
  const actions = {
    setPowtoonId,
    setSlides,
    setSelectedSlideId,
    setTimelineStart,
    setSelectedCommentId,
    setActiveModal,
    setActiveModalId,
    setMentionSuggestion,
    setPowtoonUsers,
    setCurrentUser,
    setPowtoonOwner,
    setOnlineStatus,
    setFocusStatus,
  };

  return [state, actions] as [typeof state, typeof actions];
};

export default useHostStateData;
