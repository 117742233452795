import React, { useState } from 'react';
import styled from 'styled-components';
import { SetterOrUpdater } from 'recoil';

import theme from '~/shared/theme';
import { getParentId } from '~/shared/helpers/CommentHelper';
import { useBITracking } from '~/shared/hooks/useTracking';
import { Reply as ReplyType, Comment as CommentType } from '~/shared/recoil';
import _BaseComment, { BaseCommentProps } from '~/shared/components/BaseComment';
import { removeReply } from '~/shared/utils';
import useReplyActions from '~/shared/hooks/useReplyActions';

import RepliesList from './RepliesList';

const Root = styled.div`
  margin-top: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  :hover {
    border-radius: 8px;
    cursor: pointer;
  }
`;

const BaseComment = styled(_BaseComment)`
  border-bottom: none;
`;

const Reply = (
  props: BaseCommentProps & {
    replies: ReplyType[];
    setComments?: SetterOrUpdater<CommentType[]>;
  }
) => {
  const [replyBackground, setReplyBackground] = useState(theme.colors.white);
  const { track } = useBITracking({});
  const updateWithTokenRefresher = async (next: any, args: any, tries = 1) => {
    for (let i = tries; i >= 0; i--) {
      try {
        const data = await next(args);
        return data;
      } catch (error) {
        await props.refreshPowtoonAccessToken();
      }
    }
  };

  const { updateReplyTextOnServer } = useReplyActions();
  const removeReplyHandler = (reply: ReplyType): void => {
    if (props.setComments) {
      (props.setComments as SetterOrUpdater<CommentType[]>)(
        prevComments => removeReply(prevComments, reply) as CommentType[]
      );
    }
  };

  const onUpdateButtonClick = async (richText: string) => {
    try {
      const resp = await updateWithTokenRefresher(
        updateReplyTextOnServer,
        { variables: { id: props.item.id, richText: richText } },
        1
      );
      const replyToId = getParentId(props.item.id);
      if (resp.data.updateReplyText) {
        track({
          action: 'post',
          label: 'update',
          value: '10333',
          extra: { replyToId, richText, id: props.item.id, slideId: props.item.slideId },
        });
        props.item.text = richText;
        return true;
      }
    } catch (err) {}
  };

  return (
    <Root style={{ backgroundColor: replyBackground }}>
      <BaseComment
        {...props}
        className="comment-reply Animator"
        onUpdateButtonClick={onUpdateButtonClick}
        setReplyBackground={setReplyBackground}
      >
        {props.replies && (
          <RepliesList
            replies={props.replies}
            removeReply={removeReplyHandler}
            setComments={props.setComments}
            refreshPowtoonAccessToken={props.refreshPowtoonAccessToken}
            currentFilter={props.currentFilter}
            slideId={props.item.slideId}
          />
        )}
      </BaseComment>
    </Root>
  );
};

export default Reply;
