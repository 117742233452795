import { PowtoonStudio } from '../apis';
import { PowtoonStudioProvider } from './powtoon/studio';
import { ZoidCoreManifest, zoid } from './zoid/common';
import { pluginMessageProviderFromZoidInstance } from './zoid/plugin';

export { ZoidCoreManifest };

export type { Ref as PowtoonStudioHostRef, Props as PowtoonStudioHostProps } from './PowtoonStudioHost';
export { zoid };

/**
 * The component for powtoon host to instantiate the studio plugin
 */
export { default as PowtoonStudioHost } from './PowtoonStudioHost';

/**
 * Get a PowtoonStudio to be used by the plugin.
 *
 * @param manifest plugin manifest
 */
export function powtoonStudioFromManifest(manifest: ZoidCoreManifest): PowtoonStudio {
  const messageProvider = pluginMessageProviderFromZoidInstance(manifest);
  return new PowtoonStudioProvider(messageProvider);
}

// export function powtoonStudioFromManifest(manifest: ZoidManifest): PowtoonStudio {
//   const zoidInstance = pluginZoidInstanceFromManifest(manifest);
//   const messageProvider = messageProviderFromManifest;
//   return new PowtoonStudioProvider(messageProvider);
// }

// export const powtoonStudioManifest: ZoidManifest = {
//   url: '',
//   props:
//   dimensions: {

//   }
// };

// const x: PowtoonStudioManifest = {
//   tag: 'powtoon-comments-plugin',
//   url: '',
// };

// export function powtoonStudioHostFromManifest(
//   manifest: any,
//   menuHandler: MenuPresenterHandler<StudioTarget>,
//   badgeHandler: BadgePresenterHandler<StudioTarget>
// ): PowtoonStudioHost {
//   const messageProvider = messageProviderFromManifest(manifest);
//   return new PowtoonStudioHost(messageProvider, menuHandler, badgeHandler);
// }

// export function powtoonStudioHostFromManifest(
//   manifest: any,
//   menuHandler: MenuPresenterHandler<StudioTarget>,
//   badgeHandler: BadgePresenterHandler<StudioTarget>
// ): PowtoonStudioHost {
//   const messageProvider = messageProviderFromManifest(manifest);
//   return new PowtoonStudioHost(messageProvider, menuHandler, badgeHandler);
// }
