import { PowtoonUser } from '@powtoon/plugin-lib';

export interface User {
  name: string;
  image: string;
  id: string;
}

const anonymousAuthor = {
  name: 'Guest',
  image: '',
  id: '-1',
};

export const findAuthorById = (id: string, list: { [key: string]: PowtoonUser }): User => {
  return !list[id]
    ? anonymousAuthor
    : {
        name: `${list[id].firstName} ${list[id].lastName}`,
        image: list[id].thumbUrl || anonymousAuthor.image,
        id: id,
      };
};

export const getUsersFromIds = (usersIds: any[], usersList: { [key: string]: PowtoonUser }): User[] => {
  const users: User[] = [];
  usersIds.forEach(id => {
    usersList[id]
      ? users.push({
          id,
          name: `${usersList[id].firstName} ${usersList[id].lastName}`,
          image: usersList[id].thumbUrl || anonymousAuthor.image,
        })
      : users.push(anonymousAuthor);
  });

  return users;
};
