import { gql, useQuery, useSubscription } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { PowtoonUser } from '@powtoon/plugin-lib';

import useHostStateData from '~/shared/hooks/useHostStateData';
import ChangeInfoType from '~/shared/types/ChangeInfoType';
import CommentsFilterType from '~/shared/types/CommentsFilterType';
import { commentsState, Comment, Reply } from '~/shared/recoil';
import UpdateMillisecParams from '~/shared/types/UpdateMillisecParams';

import { getCommentsRepliesStructured, orderComments, toComment, toReply } from '../helpers/CommentHelper';
/**
 * default onRecordChange mutation fields
 */
const commentFields = `
  powtoonId
  changeInfo
`;

/**
 * useComments Hook
 * @returns {}
 */
const useComments = ({
  refreshPowtoonAccessToken,
  refetchPowtoonUsers,
}: {
  refreshPowtoonAccessToken: () => void;
  refetchPowtoonUsers: (usersIds: string[]) => Promise<{ [key: string]: PowtoonUser }>;
}) => {
  const [hostDataState] = useHostStateData();
  const [clientComments, setClientComments] = useRecoilState(commentsState);
  const [filteredComments, setFilteredComments] = useState<Comment[]>([]);

  const { data: onCommentChange, loading } = useSubscription(
    gql`
            subscription ($powtoonId: String!) {
              onRecordChanged(powtoonId: $powtoonId) {
                    ${commentFields}
                }
            }
          `,
    {
      skip: !hostDataState.powtoonId || !hostDataState.currentUser,
      variables: { powtoonId: hostDataState.powtoonId },
    }
  );

  const {
    data: fetchCommentsData,
    refetch: fetchComments,
    loading: commentsLoading,
    error,
  } = useQuery(
    gql`
      query Comments($powtoonId: String!) {
        comments(powtoonId: $powtoonId) {
          id
          richText
          userId
          slideMillisec
          createdAt
          slideId
          isRead
          powtoonId
          resolvedAt
          replies {
            id
            richText
            userId
            createdAt
          }
        }
      }
    `,
    {
      variables: {
        powtoonId: hostDataState.powtoonId,
      },
      skip: !hostDataState.powtoonId || !hostDataState.currentUser || !hostDataState.slides.length,
    }
  );

  const optimisticUpdateRemove = (data: Comment, rollback?: boolean): void => {
    const commentToRemove = clientComments.find(c => c.id === data.id);
    if (rollback) {
      setClientComments(prevComments => orderComments([...prevComments, toComment(data)], hostDataState.slides));
    } else if (commentToRemove) {
      setClientComments(prevComments => prevComments.filter(comment => comment.id !== data.id));
    }
  };

  const optimisticUpdateInsert = (data: Comment, rollback?: boolean): void => {
    if (rollback) {
      setClientComments(prevComments => prevComments.filter(comment => comment.id !== data.id));
    } else {
      setClientComments(prevComments => orderComments([...prevComments, toComment(data)], hostDataState.slides));
    }
  };

  const updateCommentsMillisec = (data: UpdateMillisecParams, direction: number): void => {
    setClientComments(prevComments =>
      prevComments.map(comment => {
        if (hostDataState.selectedSlideId === comment.slideId && comment.slideMillisec >= data.start) {
          return { ...comment, slideMillisec: comment.slideMillisec + direction * data.increment };
        }
        return comment;
      })
    );
  };

  const optimisticUpdateModify = (data: UpdateMillisecParams, rollback?: boolean): void => {
    if (rollback) {
      updateCommentsMillisec(data, -1);
    } else {
      updateCommentsMillisec(data, 1);
    }
  };

  useEffect(() => {
    // error
    if (error?.message == 'Missing info in event') {
      refreshPowtoonAccessToken();

      fetchComments({ powtoonId: hostDataState.powtoonId });
    } else if (error?.message.includes("Variable 'powtoonId' has coerced Null value")) {
      // Try to fetch again in a few
      fetchComments({ powtoonId: hostDataState.powtoonId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (onCommentChange?.onRecordChanged.changeInfo) {
      const changeInfo = JSON.parse(onCommentChange.onRecordChanged.changeInfo);
      const { record } = changeInfo;
      const usersIds = Object.keys(hostDataState.powtoonUsers);
      if (record.userId && !usersIds.includes(record.userId)) {
        refetchPowtoonUsers([...usersIds, record.userId]);
      }

      const isReply = record.id.split('#').length > 2;
      switch (changeInfo.event) {
        case ChangeInfoType.INSERT:
          let isAlreadyInserted = false;
          setClientComments(prevComments =>
            orderComments(
              prevComments.map(comment => {
                if (isReply && comment.id === record.replyToId && !comment.replies.some(c => c.id === record.id)) {
                  return { ...comment, replies: [...comment.replies, toReply(record)] } as Comment;
                }
                if (comment.id === record.id) {
                  isAlreadyInserted = true;
                  return toComment(record);
                }
                return comment;
              }),
              hostDataState.slides
            )
          );

          if (!isAlreadyInserted && hostDataState.powtoonId && hostDataState.powtoonId === record.powtoonId) {
            setClientComments(prevComments =>
              orderComments([...prevComments, toComment(record)], hostDataState.slides)
            );
          }
          break;
        case ChangeInfoType.MODIFY:
          setClientComments(prevComments =>
            orderComments(
              prevComments.map(comment => {
                if (isReply && comment.id === record.replyToId) {
                  return {
                    ...comment,
                    replies: comment.replies.map(reply => (reply.id === record.id ? toReply(record) : reply)),
                  };
                }
                return comment.id === record.id ? toComment(record) : comment;
              }),
              hostDataState.slides
            )
          );
          break;
        case ChangeInfoType.REMOVE:
          setClientComments(prevComments => {
            if (isReply) {
              // remove type record only has id
              return prevComments.map(comment => {
                if (comment.replies.some(reply => reply.id === record.id)) {
                  return { ...comment, replies: comment.replies.filter(r => r.id !== record.id) } as Comment;
                }

                return comment;
              });
            }
            return prevComments.filter(comment => comment.id !== changeInfo.record.id);
          });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCommentChange?.onRecordChanged.changeInfo]);

  const extractMentionedUsersIds = (richText: string) => {
    return richText.match(/\((#mention-[^\s]+)\)/gim)?.map(mention => mention.match(/\d+/gim)?.pop());
  };

  const filterBy = useCallback(
    (type: CommentsFilterType, userId?: string): void => {
      const comments = clientComments.filter(c => c.position !== -1);
      switch (type) {
        case CommentsFilterType.ALL:
          setFilteredComments(comments.filter(c => !c.resolvedAt));
          break;
        case CommentsFilterType.RESOLVED:
          setFilteredComments(comments.filter(c => !!c.resolvedAt));
          break;
        case CommentsFilterType.UNREAD:
          setFilteredComments(comments.filter(c => !c.isRead));
          break;
        case CommentsFilterType.MENTIONED:
          setFilteredComments(
            comments.filter(
              c =>
                extractMentionedUsersIds(c.richText)?.includes(userId) ||
                c.replies.filter(reply => extractMentionedUsersIds(reply.richText)?.includes(userId)).length
            )
          );
          break;
      }
    },
    [clientComments]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getCommentAuthorIds = (comments: Comment[] | Reply[], authors?: Set<string>): Set<string> => {
    if (!authors) return getCommentAuthorIds(comments, new Set());

    comments.map((c: Comment | Reply) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      c.replies ? getCommentAuthorIds(c.replies, authors) : null;
      authors.add(c.userId!);
    });

    return authors;
  };

  const getMarshalledComments = (comments: Comment[]) => {
    const authors = getCommentAuthorIds(comments);
    const { userId } = hostDataState.currentUser;
    refetchPowtoonUsers(Array.from(authors.add(userId)));

    for (let i = 0; i < comments.length; i++) {
      comments[i].replies = getCommentsRepliesStructured(comments[i]);
    }

    return comments.filter((c: Comment) => c.id.split('#').length == 2);
  };

  useEffect(() => {
    if (fetchCommentsData) {
      // from flat replies list we build a tree hierarchy and update the clientComments list
      const comments = getMarshalledComments(fetchCommentsData.comments.map((c: Comment) => ({ ...c })));
      setClientComments(orderComments(comments, hostDataState.slides));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCommentsData]);

  useEffect(() => {
    if (hostDataState.slides) {
      setClientComments(orderComments(clientComments, hostDataState.slides));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostDataState.slides]);

  return {
    filterBy,
    fetchComments,
    filteredComments,
    fetchCommentsData,
    onCommentChange,
    optimisticUpdateInsert,
    updateCommentsMillisec,
    optimisticUpdateModify,
    optimisticUpdateRemove,
    loading: loading || commentsLoading,
  };
};

export default useComments;
