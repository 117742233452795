import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { SetterOrUpdater } from 'recoil';

import { useBITracking } from '~/shared/hooks/useTracking';
import { Comment as CommentType, Reply } from '~/shared/recoil';
import useHostStateData from '~/shared/hooks/useHostStateData';
import { formatTime } from '~/shared/utils';
import CommentsFilterType from '~/shared/types/CommentsFilterType';
import { findAuthorById } from '~/shared/helpers/UserHelper';

import Comment from './Comment';

const Root = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;
  > div:last-child {
    margin-bottom: 120px;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background: #c9cfd3;
    -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: transparent;
  }
`;

const isScrollComment = (comment: CommentType, prevComment?: CommentType, currentSlideId?: string) =>
  comment.slideId === currentSlideId && prevComment?.slideId !== currentSlideId;

const CommentsList = ({
  comments,
  currentFilter,
  onDeleteComment,
  setClientComments,
  onUpdateComment,
  onResolveComment,
  onCreateReply,
  onMarkReadStatusClick,
  refreshPowtoonAccessToken,
  onCommentClick,
}: {
  comments: CommentType[];
  currentFilter: CommentsFilterType;
  onDeleteComment: (commentId: string) => void;
  onUpdateComment: (commentId: string, richText: string, slideId: string, mentions?: any[]) => void;
  onResolveComment: (commentId: string, status: boolean) => void;
  onCreateReply: (data: Reply, parentId: string) => void;
  onMarkReadStatusClick: (commentId: string, status: boolean) => void;
  setClientComments: SetterOrUpdater<CommentType[]>;
  refreshPowtoonAccessToken: () => void;
  onCommentClick: (c: CommentType) => void;
}) => {
  const [state, actions] = useHostStateData();
  const commentRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const selectedComment = comments.find(c => c.id == state.selectedCommentId);
    const isNewCommentSlide = selectedComment && state.selectedSlideId != selectedComment.slideId;
    const isNewSlide = !selectedComment && state.selectedSlideId;
    if (isNewCommentSlide || isNewSlide) {
      const idx = state.slides.findIndex(({ id }) => id === state.selectedSlideId);
      if (idx > -1) {
        const timer = setTimeout(() => {
          rootRef.current?.scrollTo({ behavior: 'smooth', top: commentRef.current?.offsetTop, left: 0 });
        }, 200);
        actions.setSelectedCommentId(undefined);
        return () => clearTimeout(timer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedSlideId]);

  const getUserFromComment = (c: CommentType) => {
    return findAuthorById(c.userId!, state.powtoonUsers);
  };

  return (
    <Root data-cy="comments" className="comments Animator" ref={rootRef}>
      {comments.map((comment, i) => {
        const isPositionUnknown = comment.position === -1;
        return (
          <Comment
            {...{
              comment,
              currentFilter,
              onCommentClick,
              onMarkReadStatusClick,
              refreshPowtoonAccessToken,
            }}
            key={comment.id}
            ref={isScrollComment(comment, comments[i - 1], state.selectedSlideId) ? commentRef : null}
            user={getUserFromComment(comment)}
            isMoreButton={
              comment.userId == state.currentUser.userId || state.currentUser.userId == state.powtoonOwner?.id
            }
            onDeleteButtonClick={() => onDeleteComment(comment.id)}
            onUpdateButtonClick={(richText: string, slideId: string, mentions?: any[]) => {
              onUpdateComment(comment.id, richText, slideId, mentions);
            }}
            onCreateReplyClick={(data: Reply, commentId: string) => {
              onCreateReply(data, commentId);
            }}
            onResolveButtonClick={(id: string, status: boolean) => onResolveComment(id, status)}
            timeInfo={{
              text: formatTime(comment.slideMillisec),
            }}
            positionInfo={{
              text: `Slide ${isPositionUnknown ? 'unknown' : comment.position}`,
              isError: isPositionUnknown,
            }}
            setComments={setClientComments}
            isRepliesEnabled={true}
          />
        );
      })}
    </Root>
  );
};

export default CommentsList;
