import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

    body, html {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      font-family: proxima-nova, sans-serif;
    }

    #app {
      width: 100%;
      height: 100%;
      /* border: solid 4px green; */
      display: flex;
    }

    * {

      box-sizing: border-box;
      
    }

    button:hover {
      cursor: pointer;
    }

    textarea::placeholder {
      color: rgba(10, 33, 56, 0.5);  
    }

    input::placeholder {
      color: rgba(10, 33, 56, 0.5);  
    }

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }

    .Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }

  .Animator {
    animation: 300ms ease-out 0s 1 animate-comments;
    @keyframes animate-comments {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

`;

export default GlobalStyle;
