import React from 'react';

import { COMMENT_ABILITY } from '~/shared/helpers/PermissionHelper';

interface PermissionProps {
  children: JSX.Element;
  to: COMMENT_ABILITY;
  user: { permissions: COMMENT_ABILITY[] };
  message?: string | JSX.Element;
}

export const isActionAllowed = (action: COMMENT_ABILITY, perms: string[]) => perms.indexOf(action) > -1;

export const IsAllowed = function ({ children, to, user, message }: PermissionProps) {
  if (user?.permissions && isActionAllowed(to, user?.permissions)) return children;
  return message ? <span>{message}</span> : null;
};

export const IsNotAllowed = function ({ children, to, user, message }: PermissionProps) {
  if (user?.permissions && !isActionAllowed(to, user?.permissions)) return children;
  return message ? <span>{message}</span> : null;
};
