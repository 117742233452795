import { PowtoonAccessToken } from '@powtoon/plugin-lib';

import { setPowtoonAccessToken, getTokensPayload } from '~/shared/jwt';

interface fetchUserParams {
  getPowtoonAccessToken: () => Promise<string>;
}

export const fetchPowtoonAccessToken = async (props: fetchUserParams): Promise<PowtoonAccessToken> => {
  const powtoonAccessToken = await props.getPowtoonAccessToken();
  setPowtoonAccessToken(powtoonAccessToken);
  return getTokensPayload(powtoonAccessToken);
};
