import React from 'react';
import ReactDOM from 'react-dom';

import { ZoidCoreProps, ZoidCoreManifest, zoid } from './common';

type HostComponent = React.FunctionComponent<ZoidCoreProps & { width: string; height: string }>;
/**
 * host views the zoid instance as a factory for creating a host component
 */
interface ZoidHostInstance {
  driver(name: 'react', args: { React: any; ReactDOM: any }): HostComponent;
}

export function hostComponentFromZoidManifest(manifest: ZoidCoreManifest): HostComponent {
  zoid.destroy();
  const zoidInstance: ZoidHostInstance = zoid.create(manifest);
  return zoidInstance.driver('react', { React, ReactDOM });
}
