import React, { useState, useCallback, forwardRef, Ref, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { convertToRaw, EditorState } from 'draft-js';
import { useRecoilState, SetterOrUpdater } from 'recoil';
import { draftToMarkdown } from 'markdown-draft-js';
import { Text } from '@powtoon/design-system-components';

import ButtonsPanel from '~/shared/components/ButtonsPanel';
import RichTextEditor from '~/shared/components/editor/RichTextEditor';
import { commentEditorState, activeReplyEditorState } from '~/shared/recoil';
import useHostStateData from '~/shared/hooks/useHostStateData';
import { IsAllowed } from '~/shared/components/Acl/Abilities';
import { Abilities } from '~/shared/helpers/PermissionHelper';
import { useBITracking } from '~/shared/hooks/useTracking';

const Root = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const TextWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.D20};
  color: ${({ theme }) => theme.colors.D30};
  padding: 8px 0px 0px 15px;
  font-size: 14px/20px;
  align-content: center;
  min-height: 40px;
  border-radius: 5px;
`;

const EmptyInputWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.divider};
  margin: 0 15px 15px 15px;
  width: 90%;
  padding: 20px 0px 0px 0px;
`;

const FormContainer = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.divider};
  padding: 20px 0;
  width: 90%;
  margin: 0 15px;
`;

const ButtonWrapper = styled.div`
  background: ${({ theme }) => theme.colors.backgroundLight};
  display: flex;
  padding: 30px;
  margin-top: 30px;
  min-height: 104px;
  justify-content: space-between;
`;

const BottomText = styled(Text)`
  /* color: ${({ theme }) => theme.colors.secondary}; */
  margin-bottom: 0px;
`;

const ActionText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

const ErrorMessage = styled(Text)`
  width: 100%;
  margin: 0px;
`;

interface RichTextFormProps {
  onSubmit: (val: string, mentions: any[]) => void;
  currentTime?: number;
  setIsRichTextFocused?: SetterOrUpdater<boolean>;
  onInviteBtnClick: () => void;
}

const RichTextForm = (
  { onSubmit, currentTime, setIsRichTextFocused, onInviteBtnClick }: RichTextFormProps,
  ref: Ref<HTMLDivElement>
) => {
  const [inputValue, setInputValue] = useState(() => EditorState.createEmpty());
  const [errorText, setErrorText] = useState('');
  const [isActiveInputWrapper, setActiveInputWrapper] = useState(false);
  const [editorBox, setEditorBox] = useRecoilState(commentEditorState);
  const [mentions, setMentions] = useState([]);
  const richTextRef = useRef<RichTextEditor>(null);
  const emptyInputRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [replyEditorBox, setReplyEditorState] = useRecoilState(activeReplyEditorState);
  const [hostDataState] = useHostStateData();
  const { track } = useBITracking({});

  const activationHandler = useCallback(
    (isSet: boolean): void => {
      setActiveInputWrapper(isSet);
      setIsRichTextFocused?.(isSet);
    },
    [setActiveInputWrapper, setIsRichTextFocused]
  );
  const focusEditor = useCallback(() => {
    setErrorText('');
    setEditorBox(true);
    activationHandler(true);
    richTextRef.current?.editor.focus();
  }, [activationHandler, setEditorBox]);
  // const handleShowButtons = useCallback(() => {
  //   window.console.log('handleShowButtons');
  //   activationHandler(true);
  //   setActiveInputWrapper(true);
  // }, [setActiveInputWrapper, activationHandler]);
  const handleCancelButtons = useCallback(() => {
    setErrorText('');
    setEditorBox(false);
    setActiveInputWrapper(false);
    activationHandler(false);
  }, [setActiveInputWrapper, setEditorBox, activationHandler]);

  const handleChange = useCallback((e: EditorState, n: any) => {
    setMentions(n);
    setInputValue(e);
  }, []);

  const isInFocus = richTextRef.current?.state.editorState.getSelection().getHasFocus();
  const shouldFocusOnRender = emptyInputRef && !isActiveInputWrapper;

  useEffect(() => {
    if (isInFocus && shouldFocusOnRender && editorBox) {
      focusEditor();
    }
  }, [isInFocus, focusEditor, shouldFocusOnRender, editorBox]);

  const handleSend = useCallback(() => {
    if (!inputValue.getCurrentContent().hasText()) {
      setErrorText('Enter message content to send');
    } else {
      const newMarkdownText = draftToMarkdown(convertToRaw(inputValue.getCurrentContent()), {
        entityItems: {
          mention: {
            open: function () {
              // @ts-ignore
              return `[`;
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            close: function (entity) {
              // @ts-ignore
              return `](#mention-${entity.data.mention.id})`;
            },
          },
        },
      });
      //  Save plain Text
      //  onSubmit(inputValue.getCurrentContent().getPlainText());
      //  Save as markdown
      onSubmit(newMarkdownText, mentions);
      setErrorText('');
      activationHandler(false);
    }
  }, [inputValue, activationHandler, setErrorText, onSubmit, mentions]);

  const activateEditor = useCallback(
    _evt => {
      setReplyEditorState('');
      setEditorBox(true);
      activationHandler(true);
      setActiveInputWrapper(true);
    },
    [setActiveInputWrapper, activationHandler, setReplyEditorState, setEditorBox]
  );
  const onInviteBtnClickWrapper = () => {
    track({
      action: 'click',
      label: 'invite_plugin_bottom',
      value: '10342',
    });
    onInviteBtnClick();
  };
  const milliSecToMinutes = (milliseconds: number | undefined): string => {
    if (milliseconds == undefined) return '';
    const minutes: number = Math.floor(milliseconds / 60000);
    const seconds: string = ((milliseconds % 60000) / 1000).toFixed(0);
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds.length < 2 ? '0' : ''}${seconds}`;
  };

  return (
    <Root ref={ref} data-cy="rich-text-form">
      <FormContainer data-cy="form-container">
        {isActiveInputWrapper && editorBox ? (
          <InputWrapper className="Animator">
            <RichTextEditor
              ref={richTextRef}
              handleSend={handleSend}
              onChange={handleChange}
              innerText={milliSecToMinutes(currentTime)}
              mentionSuggestions={hostDataState.mentionSuggestions}
            />
            <ErrorMessage fontStyle="medium" color="destructive">
              {errorText}
            </ErrorMessage>
            <ButtonsPanel onCancelButtonClick={handleCancelButtons} disabled={false} onOkButtonClick={handleSend} />
          </InputWrapper>
        ) : (
          <EmptyInputWrapper
            ref={emptyInputRef}
            onClick={activateEditor}
            data-cy="empty-input-wrapper"
            className="Animator"
          >
            <TextWrapper> Add a comment or @mention </TextWrapper>
            <ButtonsPanel onCancelButtonClick={handleCancelButtons} disabled={true} onOkButtonClick={handleSend} />
          </EmptyInputWrapper>
        )}
        <ButtonWrapper>
          <IsAllowed to={Abilities.SHARE_POWTOON} user={hostDataState.currentUser}>
            <BottomText fontStyle="default">
              <ActionText onClick={onInviteBtnClickWrapper}>Invite</ActionText> a colleague to start <br /> a
              conversation.
            </BottomText>
          </IsAllowed>
        </ButtonWrapper>
      </FormContainer>
    </Root>
  );
};

export default forwardRef(RichTextForm);
