// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { PowtoonUser } from '@powtoon/plugin-lib';

export interface Reply {
  id: string;
  richText: string;
  createdAt: string;
  replies: Reply[];
  // to read the author id optional attribute for
  // creating replies (author is taken from token)
  userId?: string;
}

export interface Comment {
  id: string;
  richText: string;
  // comes from env
  createdAt: string;
  // comes from current state of powtoon
  // later, even if powtton deleted, changed etc,
  // we do not care. we show if relevant to powtoon
  // if slide changes position, we also do not care
  powtoonId: string; // serves as thread id
  slideId: string;
  slideMillisec: number;
  replies: Reply[];
  position?: number;
  resolvedAt?: string;
  isRead?: boolean;
  // to read the author id optional attribute for
  // creating replies (author is taken from token)
  userId?: string;
}

export interface MentionData {
  id?: string;
  name: string;
  avatar?: string;
  link?: string;
}

export interface Slide {
  id: string;
  duration_millisec: number;
}

export interface PowtoonDocument {
  id: string;
  display_name: string;
  slides: Slide[];
}

export interface AppOnlineStatus {
  isOnline: boolean;
}

export const commentsState = atom<Comment[]>({
  key: 'comments',
  default: [],
});

export const powtoonSlidesState = atom<Slide[]>({
  key: 'powtoonSlides',
  default: [],
});

export const selectedSlideIdState = atom<string | undefined>({
  key: 'selectedSlideId',
  default: undefined,
});

export const selectedCommentIdState = atom<string | undefined>({
  key: 'selectedCommentId',
  default: undefined,
});

export const powtoonIdState = atom<string | undefined>({
  key: 'powtoonId',
  default: undefined,
});

export const timelineState = atom<number>({
  key: 'timeline',
  default: 0,
});

export const commentEditorState = atom<boolean | undefined>({
  key: 'comment_editor',
  default: false,
});

export const activeReplyEditorState = atom<string | undefined>({
  key: 'active_reply_editor',
  default: undefined,
});

export const isMenuActive = atom<boolean>({
  key: 'is_active_modal',
  default: false,
});

export const isAppOnline = atom<boolean>({
  key: 'is_app_active',
  default: true,
});

export const focusState = atom<boolean>({
  key: 'is_plugin_active',
  default: false,
});

export const activeMenu = atom<string>({
  key: 'selected_active_modal',
  default: '',
});

export const powtoonMentionSuggestion = atom<MentionData[]>({
  key: 'editor-mention-suggestion-list',
  default: [],
});

export const powtoonUsersList = atom<{ [key: string]: PowtoonUser }>({
  key: 'powtoon-users-list',
  default: {},
});

export const currentUserState = atom<any>({
  key: 'current-loggedIn-user',
  default: null,
});

export const powtoonOwnerState = atom<MentionData | undefined>({
  key: 'powtoonOwnerData',
  default: undefined,
});
