import { TrackingComponent, useTracking as useTrackingAbstract } from 'react-tracking';
import axios from 'axios';
import { useRef } from 'react';

import useHostStateData from '~/shared/hooks/useHostStateData';
//@TODO discuss to move to Plugin-lib
export type BIAction = 'click' | 'filter' | 'delete' | 'edit' | 'read' | 'unread' | 'resolve' | 'reopen' | 'post';
export type BILabel =
  | 'post'
  | 'reply'
  | 'update'
  | 'comment_context_menu'
  | 'context_menu'
  | 'open'
  | 'resolved'
  | 'unread'
  | 'mentions'
  | 'invite_empty_state'
  | 'invite_plugin_bottom';

export type BIEventType = {
  user: string;
  powtoonId: string;
  category: 'commenting_plugin';
  action: BIAction;
  label: BILabel;

  value:
    | '10331'
    | '10332'
    | '10333'
    | '10334'
    | '10335'
    | '10336'
    | '10337'
    | '10338'
    | '10339'
    | '10340'
    | '10341'
    | '10342';
  extra: { [key: string]: string };
};

type BiTrackingProps = {
  trackingDSN?: string;
};

export const useBITracking = ({
  trackingDSN,
}: BiTrackingProps): {
  track: (data: Partial<BIEventType>) => void;
  BIEventTracker: TrackingComponent<BIEventType>;
} => {
  const { trackEvent } = useTrackingAbstract();
  const tDSN = useRef(trackingDSN);
  const [hostDataState] = useHostStateData();

  const TrackComponent = () => {
    const { Track } = useTrackingAbstract<BIEventType>(
      {
        category: 'commenting_plugin',
        powtoonId: `${hostDataState.powtoonId}`,
        user: `${hostDataState.currentUser?.userId}`,
      },
      {
        dispatch: data => {
          window.console.log('BI EVENT', { ...data });

          // window.console.log('BI EVENT', { ...data });
          tDSN?.current
            ? axios
                .get(tDSN?.current, {
                  params: {
                    u: data.user,
                    p: data.powtoonId,
                    c: data.category,
                    a: data.action,
                    l: data.label,
                    v: data.value,
                    d: JSON.stringify(data.extra),
                    _pc: Date.now(),
                  },
                })
                // eslint-disable-next-line no-console
                .catch(err => console.log('error', err))
            : null;
        },
      }
    );

    return Track;
  };

  return {
    track: trackEvent,
    BIEventTracker: TrackComponent(),
  };
};
