import { CoreMessagingProvider } from '../../apis';
import { ZoidCoreManifest, ZoidCoreProps, zoid } from './common';

/**
 * plugin view of the zoid instance
 */
interface PluginZoidInstance {
  xprops: ZoidCoreProps;
}

export function pluginMessageProviderFromZoidInstance(manifest: ZoidCoreManifest): CoreMessagingProvider {
  const zoidInstance: PluginZoidInstance = zoid.create(manifest);
  const provider: CoreMessagingProvider = {
    send(msg) {
      zoidInstance.xprops.onMessageFromPlugin(msg);
    },
    setReceiver(receiver) {
      zoidInstance.xprops.onSetPluginMessageHandler(receiver);
    },
    sendReceive(msg) {
      return zoidInstance.xprops.onPromiseMessageFromPlugin(msg);
    },
  };
  return provider;
}
