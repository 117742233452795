import React from 'react';
import styled from 'styled-components';
import { Text, Button as _Button } from '@powtoon/design-system-components';

import { useBITracking } from '~/shared/hooks/useTracking';
import InviteMemberGif from '~/shared/images/Invite-member.gif';

const MainWrapper = styled.div`
  justify-content: center;
  margin-top: -50%;
`;
const Image = styled.img`
  width: 175px;
  height: 175px;
  margin-bottom: 10px;
`;

const ShortTextWrappers = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

const LongTextWrappers = styled.div`
  display: flex;
  text-align: center;
  margin-top: 7px;
  justify-content: center;
`;

const LongText = styled(Text)`
  letter-spacing: 0.14px !important;
`;

const ButtonWrapper = styled.div`
  padding-left: 43 px;
  padding-right: 43 px;
  padding-top: 20px;
`;

const EmptyComments = ({
  onInviteBtnClick,
  title,
  message,
}: {
  message: string;
  onInviteBtnClick?: () => void;
  title?: string;
}) => {
  const { track } = useBITracking({});

  const onInviteBtnClickWrapper = () => {
    onInviteBtnClick && onInviteBtnClick();
    track({
      action: 'click',
      label: 'invite_empty_state',
      value: '10341',
    });
  };

  return (
    <MainWrapper>
      <Image src={InviteMemberGif} />
      {title && (
        <ShortTextWrappers>
          <Text fontStyle="h3">{title}</Text>
        </ShortTextWrappers>
      )}
      <LongTextWrappers>
        <LongText fontStyle="h5">{message}</LongText>
      </LongTextWrappers>
      <ButtonWrapper>
        {onInviteBtnClick && (
          <_Button iconName="invite" size="medium" buttonType="primary" onClick={onInviteBtnClickWrapper}>
            Invite people
          </_Button>
        )}
      </ButtonWrapper>
    </MainWrapper>
  );
};

export default EmptyComments;
