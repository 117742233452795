import React, { useRef, useImperativeHandle, forwardRef, memo } from 'react';

import {
  BadgePresenter,
  CoreMessage,
  CoreMessageHandler,
  MenuPresenter,
  StudioModelEvent,
  PluginBaseHandler,
  StudioTarget,
  StudioUiEvent,
  NavigationPresenter,
} from '../apis';
import { ZoidCoreManifest } from './zoid/common';
import { hostComponentFromZoidManifest } from './zoid/host';

// function useCorePlugin() {
//   const proxy = useRef<CoreMessageHandler>();
//   //const [lastMessageFromPlugin, setLastMessageFromPlugin] = useState<any>();
//   function onSetPluginMessageHandler(handler: CoreMessageHandler) {
//     console.warn('handler__', handler);
//     proxy.current = handler;
//   }

//   function sendMessageToPlugin(msg: CoreMessage): void {
//     console.warn('sendMessageToPlugin', msg);
//     return proxy.current?.(msg);
//   }

//   //   function onMessageFromPlugin(msg: CoreMessage) {
//   //     // eslint-disable-next-line no-console
//   //     console.log('onMessageFromPlugin', msg);
//   //     // setLastMessageFromPlugin(lastMessageFromPlugin);
//   //   }

//   return {
//     sendMessageToPlugin,
//     onSetPluginMessageHandler,
//     // onMessageFromPlugin,
//   };
// }

// function usePowtoonStudioPlugin(manifest: ZoidCoreManifest) {
//   const { sendMessageToPlugin, onSetPluginMessageHandler, onMessageFromPlugin } = useCorePlugin();
//   const { current: Cmp } = useRef(hostComponentFromZoidManifest(manifest));
// }

// export function powtoonStudioHostComponentFromManifest(manifest: ZoidCoreManifest) {
//   const Cmp = hostComponentFromZoidManifest(manifest);
//   return Cmp;
// }

// export type Props = ZoidCoreProps & { manifest: ZoidCoreManifest };

// export function PowtoonStudioHost(props: Props) {
//   const { current: Cmp } = useRef(powtoonStudioHostComponentFromManifest(props.manifest));
//   return (
//     <Cmp
//       onMessageFromPlugin={props.onMessageFromPlugin}
//       onSetPluginMessageHandler={props.onSetPluginMessageHandler}
//     ></Cmp>
//   );
// }

// export function PowtoonStudioHost2({ manifest, onSetBadge, onSetMenu }: Props2) {
//   const apiName = 'PowtoonStudio';
//   const { current: Cmp } = useRef(hostComponentFromZoidManifest(manifest));
//   const proxy = useRef<CoreMessageHandler>();
//   function onSetPluginMessageHandler(handler: CoreMessageHandler) {
//     console.warn('onSetPluginMessageHandler', handler);
//     proxy.current = handler;
//   }

//   function sendMessageToPlugin(msg: CoreMessage): void {
//     console.warn('sendMessageToPlugin', msg);
//     return proxy.current?.(msg);
//   }

//   function onMessageFromPlugin(msg: CoreMessage) {
//     // eslint-disable-next-line no-console
//     console.log('onMessageFromPlugin', msg);

//     if (msg.apiName === apiName) {
//       if (msg.methodName === 'setBadge') {
//         return onSetBadge(msg.arg);
//       }
//       if (msg.methodName === 'setMenu') {
//         return onSetMenu(msg.arg);
//       }
//     }
//   }
//   //   const { sendMessageToPlugin, onSetPluginMessageHandler, onMessageFromPlugin } = useCorePlugin();

//   return <Cmp onMessageFromPlugin={onMessageFromPlugin} onSetPluginMessageHandler={onSetPluginMessageHandler}></Cmp>;
// }

type MenuHandler = MenuPresenter<StudioTarget>['setMenu'];
type BadgeHandler = BadgePresenter<StudioTarget>['setBadge'];
type onReadyHandler = PluginBaseHandler['onReady'];
type onGetDataHandler = PluginBaseHandler['onGetDataHandler'];
type ShareDialogHandler = PluginBaseHandler['onOpenShareDialog'];
type NotifyUsersHandler = PluginBaseHandler['onNotifyUsers'];
type PausePlayerHandler = PluginBaseHandler['onPause'];
type NavigationHandler = NavigationPresenter<StudioTarget>['navigateToTarget'];

export type Props = { manifest: ZoidCoreManifest } & {
  onSetBadge: BadgeHandler;
  onSetMenu: MenuHandler;
  onReady: onReadyHandler;
  onGetDataRequest: onGetDataHandler;
  onOpenShareDialog: ShareDialogHandler;
  onNotifyUsers: NotifyUsersHandler;
  onPause: PausePlayerHandler;
  onNavigateToTarget: NavigationHandler;
};

export type Ref = {
  notifyUiEvent(event: StudioUiEvent): void;
  notifyModelEvent(event: StudioModelEvent): void;
};

export const PowtoonStudioHost = forwardRef<Ref, Props>(
  (
    {
      manifest,
      onSetBadge,
      onSetMenu,
      onReady,
      onGetDataRequest,
      onNotifyUsers,
      onOpenShareDialog,
      onNavigateToTarget,
      onPause,
    },
    ref
  ) => {
    const apiName = 'PowtoonStudio';
    const { current: PluginWindowCmp } = useRef(hostComponentFromZoidManifest(manifest));
    const proxy = useRef<CoreMessageHandler>();

    function sendMessageToPlugin(msg: CoreMessage): void {
      // eslint-disable-next-line no-console
      // console.log('sendMessageToPlugin', msg);
      return proxy.current?.(msg);
    }

    function onSetPluginMessageHandler(handler: CoreMessageHandler) {
      // eslint-disable-next-line no-console
      // console.log('onSetPluginMessageHandler', handler);
      proxy.current = handler;
    }

    function onMessageFromPlugin(msg: CoreMessage) {
      // eslint-disable-next-line no-console
      // console.log('onMessageFromPlugin', msg);

      if (msg.apiName === apiName) {
        if (msg.methodName === 'setBadge') {
          return onSetBadge(msg.arg);
        }
        if (msg.methodName === 'setMenu') {
          return onSetMenu(msg.arg);
        }

        if (msg.methodName === 'openShareDialog') {
          return onOpenShareDialog(msg.arg);
        }

        if (msg.methodName === 'notifyUsers') {
          return onNotifyUsers(msg.arg);
        }

        if (msg.methodName === 'ready') {
          return onReady();
        }

        if (msg.methodName === 'pause') {
          return onPause();
        }

        if (msg.methodName === 'navigateToTarget') {
          return onNavigateToTarget(msg.arg);
        }
      }
    }

    function onPromiseMessageFromPlugin(msg: CoreMessage): Promise<any> {
      if (msg.apiName === apiName) {
        return onGetDataRequest(msg.methodName, msg.arg);
      }
      return Promise.reject({ error: `${msg.methodName} :: Method not supported` });
    }

    useImperativeHandle(ref, () => ({
      notifyUiEvent(event: StudioUiEvent): void {
        sendMessageToPlugin({ apiName, methodName: 'StudioUiEvent', arg: event });
      },
      notifyModelEvent(event: StudioModelEvent): void {
        sendMessageToPlugin({ apiName, methodName: 'StudioModelEvent', arg: event });
      },
    }));

    //   const { sendMessageToPlugin, onSetPluginMessageHandler, onMessageFromPlugin } = useCorePlugin();

    return (
      <PluginWindowCmp
        width="100%"
        height="100%"
        onMessageFromPlugin={onMessageFromPlugin}
        onSetPluginMessageHandler={onSetPluginMessageHandler}
        onPromiseMessageFromPlugin={onPromiseMessageFromPlugin}
      ></PluginWindowCmp>
    );
  }
);

// TODO: fix issues with single instance of bridge

export default memo(PowtoonStudioHost, () => true);
