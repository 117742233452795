type AppConfig = {
  JWTCookieName: string;
  PowtoonTrackingDSN: string;
  PingTimeout: number;
  OnlinePollingInterval: number;
  logzioUrl: string;
  SentryDSN?: string;
  logzioToken?: string;
  logzioAppVersion?: string;
  logzioAppLevel?: string;
};

export const appConfig: AppConfig = {
  SentryDSN: process.env.REACT_APP_SENTRY_DSN,
  JWTCookieName: process.env.REACT_APP_JWT_COOKIE_NAME || 'powtoonAccessToken',
  PowtoonTrackingDSN: process.env.REACT_APP_POWTOON_TRACKING_DSN || 'https://trek-w3.powtoon.com/event.gif',
  PingTimeout: Number(process.env.TIMEOUT_TIME_MS) || 1300,
  OnlinePollingInterval: Number(process.env.REACT_APP_ONLINE_PING_POLLING_INTERVAL_MS) || 3000,
  logzioUrl: process.env.REACT_APP_DEFAULT_LOGZIO_URL || 'https://listener.logz.io:8091',
  logzioAppLevel: process.env.REACT_APP_DEFAULT_LOGZIO_APP_LEVEL || 'prod',
  logzioToken: process.env.REACT_APP_DEFAULT_LOGZIO_TOKEN,
  logzioAppVersion: process.env.REACT_APP_DEFAULT_LOGZIO_APP_VERSION,
};
