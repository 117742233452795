import React from 'react';
import styled from 'styled-components';
import { Text } from '@powtoon/design-system-components';

import EmptyCommentsGif from '~/shared/images/comment_empty.gif';

const MainWrapper = styled.div`
  justify-content: center;
  margin-top: -50%;
`;

const Image = styled.img`
  width: 175px;
  height: 175px;
  margin-bottom: 10px;
`;

const LongTextWrappers = styled.div`
  display: flex;
  text-align: center;
  margin-top: 7px;
  justify-content: center;
`;

const LongText = styled(Text)`
  letter-spacing: 0.14px !important;
`;

const emptyCommentsGray = ({ message }: { message: string }) => {
  return (
    <MainWrapper>
      <Image src={EmptyCommentsGif} />
      <LongTextWrappers>
        <LongText fontStyle="h5">{message}</LongText>
      </LongTextWrappers>
    </MainWrapper>
  );
};

export default emptyCommentsGray;
