import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Text, UserAvatars as _UserAvatars, Icon } from '@powtoon/design-system-components';

import { Comment as CommentType } from '~/shared/recoil';
import useHostStateData from '~/shared/hooks/useHostStateData';
import { getUsersFromIds, User } from '~/shared/helpers/UserHelper';

const Button = styled(Text)`
  display: inline;
  margin: 0px;
  margin-left: 10px;
`;

const ArrowDownIcon = styled(Icon)`
  vertical-align: top;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.primary};
`;

const ImageCounter = styled(Text)`
  display: inline-block;
  margin: 0px;
  margin-left: 5px;
  margin-top: 3px;
  vertical-align: top;
`;

const Root = styled.div`
  height: 55px;
  margin-top: 5px;
`;
const WrapperWithBorder = styled.div`
  padding: 10px 0px 10px 15px;
  height: 55px;
  cursor: pointer;
  border-radius: 8px;
  :hover {
    background: ${({ theme }) => theme.colors.backgroundHover};
  }
`;
const UserAvatars = styled(_UserAvatars)`
  img {
    border-radius: 25px;
  }
`;
const BorderSpanElement = styled.div`
  padding-left: 10px;
  border-left: 2px solid ${({ theme }) => theme.colors.primary};
  :hover {
    background: ${({ theme }) => theme.colors.backgroundHover};
  }
`;

interface BaseCommentRepliesProps {
  comment: CommentType;
  setRepliesShown: (status: boolean) => void;
}
const BaseCommentReplies = ({ comment, setRepliesShown }: BaseCommentRepliesProps) => {
  const [hostData] = useHostStateData();
  const [replyUsers, setReplyUsers] = useState<User[]>([]);

  useEffect(() => {
    if (!isEmpty(hostData.powtoonUsers))
      if (!hostData.powtoonUsers.length) {
        const uniqueUsersIds = Array.from(new Set(comment.replies.map(reply => reply.userId)));
        setReplyUsers(getUsersFromIds(uniqueUsersIds, hostData.powtoonUsers));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, hostData.powtoonUsers]);

  return (
    <Root>
      <WrapperWithBorder data-cy="show-replies-button" onClick={() => setRepliesShown(true)}>
        <BorderSpanElement>
          <div style={{ paddingTop: 5 }}>
            <UserAvatars avatarsSize="small" maxVisibleItem={3} users={replyUsers} />
            <Button>
              <ArrowDownIcon name="arrow_down" color="primary" size="medium" />
              <ImageCounter color="primary" fontStyle="medium">
                Show {comment.replies.length} {comment.replies.length > 1 ? 'replies' : 'reply'}
              </ImageCounter>
            </Button>
          </div>
        </BorderSpanElement>
      </WrapperWithBorder>
    </Root>
  );
};
export default BaseCommentReplies;
